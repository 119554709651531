import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { AuthService } from 'src/app/_services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tangram-show-profile-modal',
  templateUrl: './show-profile-modal.component.html',
  styleUrls: ['./show-profile-modal.component.scss']
})
export class ShowProfileModalComponent implements OnInit {
  iconPath = appConfig.iconPath;
  imgPath = appConfig.imagePath;
  imgEnvPath = environment.backendUrl;
  userDetail;
  currentUser: any;
  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private dashboardService: DashboardService) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
  }

  /**
   * follow user in community
   * @param userData user deatil
   */
  followUnfolloWUser(userData) {
    if (userData._id != this.currentUser._id) {
      let data = {
        "_id": userData._id,
        "isFollowed": !userData.isFollowed
      };
      this.dashboardService.followUnfollowUser(data).subscribe(() => {
        userData.isFollowed = data.isFollowed;
      });
    }
  }
}
