import { Location } from '@angular/common';
import { DashboardService } from './../../services/dashboard.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'edit-tangram',
  templateUrl: './edit-tangram.component.html',
  styleUrls: ['./edit-tangram.component.scss']
})
export class EditTangramComponent implements OnInit, OnDestroy {
  appConfig = appConfig;
  iconPath = appConfig.iconPath; // To store icon path
  setupPathwayForm: FormGroup; // To represent setup profile form
  profileImageUrl: string = ""; // To store profile image url
  currentUser: any;
  imgPath = appConfig.imagePath;
  currentPathwayId = '';
  tanTitle: any = '';
  isLoaded: boolean = false;
  tanDesc: any = '';
  tanDescLength = 0;
  initialDescLength = 0;
  constructor(
    private location: Location,
    private commonService: CommonService,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private router: Router, 
    ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((param: any) => {
      this.currentPathwayId = param.pathwayId;
    });
    // Get profile
    this.getPathway(this.currentPathwayId);
    
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.commonService.changeView('');
  }

  /**
   * Go back to previous route
   */
  backButton() {
    this.location.back();
  }

  /**
   * Get profile
   */
  getPathway(pathwayId: any) {
    this.dashboardService.getPathwayDetail(pathwayId).subscribe((res: any) => {
      const { title, description } = res.data;
      this.isLoaded = true;
      this.tanTitle = title;
      this.tanDesc = description;
      this.tanDescLength = description.length;
      this.initialDescLength = this.tanDescLength;
    });
  }

  /**
   * Calculate length of description
   */
  calculateDescLength(event: any) {
    let newDescLength = (event.target.value).length;
    this.tanDescLength = newDescLength;
  }

  /**
   * Update Tangram Details
   */
  updateTangram() {
    let data = {
      "pathwayId": this.currentPathwayId,
      "title": this.tanTitle,
    };
    data["description"] = this.tanDesc
    this.dashboardService.updateTangramDetails(data).subscribe((response: any) => {
      this.router.navigate(['/dashboard', this.currentPathwayId]);
    });
  }

}
