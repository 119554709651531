import { Component } from '@angular/core';
import { appConfig } from '../../../_core/config/app-config.const';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tangram-flag-confirmation-modal',
  templateUrl: './flag-confirmation-modal.component.html',
  styleUrls: ['./flag-confirmation-modal.component.scss']
})
export class FlagConfirmationModalComponent {

  iconPath = appConfig.iconPath; // To store icon path
  constructor(
    public activeModal: NgbActiveModal
  ) { }
}
