import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  constructor() { }

  /**
   * sort array
   * @param array array to be sorted
   * @param property array to be sorted by property
   * @param isAscending possible values:[true,false] order defines ascending and descending order
   */
  public sortArray(array:any[], property:string, isAscending:boolean) {
    if (isAscending) {
      array.sort(function (a, b) {
        return a[property] - b[property];
      });
    } else {
      array.sort(function (a, b) {
        return b[property] - a[property];
      });
    }
  }
}
