import { Component, Input, OnInit } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';

declare var $: any;
@Component({
  selector: 'tangram-pathway-detail',
  templateUrl: './pathway-detail.component.html',
  styleUrls: ['./pathway-detail.component.scss']
})
export class PathwayDetailComponent implements OnInit {
  @Input() pathwayDetail: any;
  @Input() isDashboard: boolean;
  iconPath = appConfig.iconPath;
  constructor() { }

  ngOnInit() {
  }

  /**
   * Show video description modal
   */
  showVideoDescriptionModal() {
    $("#videoDescriptionModal").appendTo('body').modal('show');
  }

}
