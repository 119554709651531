import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[tangramDragSlider]'
})
export class DragSliderDirective {

  isMouseDown = false; // To check whether mouse is down or not
  startX; // To store mouse position
  scrollLeft; // To store current scroll position when drag starts

  constructor(private el: ElementRef) { }

  /**
   * Called when mouse gets down
   * @param e
   */
  @HostListener('mousedown', ['$event'])
  onMouseDown(e) {
    this.startDragging(e);
  }

  /** Called when mouse gets up */
  @HostListener('mouseup', [''])
  onMouseUp() {
    this.stopDragging();
  }

  /** Called when mouse gets leaved */
  @HostListener('document.mouseleave', [''])
  onMouseLeave() {
    this.stopDragging();
  }

  /**
   * Called when mouse gets moved
   * @param e
   */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    // dragging posible if mouse is down
    if (!this.isMouseDown) { return; };
    e.preventDefault();
    const x = e.pageX - this.el.nativeElement.offsetLeft;
    const scroll = (x - this.startX);
    this.el.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }

  /**
   * Start dragging
   * @param e
   */
  startDragging(e) {
    this.isMouseDown = true;
    this.startX = e.pageX - this.el.nativeElement.offsetLeft;
    this.scrollLeft = this.el.nativeElement.scrollLeft;
  }

  /** Stop dragging */
  stopDragging() {
    this.isMouseDown = false;
  }
}
