import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { ValidationService } from 'src/app/_core/services/validation.service';
import { AuthService } from 'src/app/_services/auth.service';
import * as validationMsg from "src/app/_core/_helpers/validationMsg.json";
import { ToastrService } from 'ngx-toastr';
import { CommonModalComponent } from 'src/app/shared/components/common-modal/common-modal.component';

@Component({
  selector: 'tangram-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  iconPath = appConfig.iconPath;
  imgPath = appConfig.imagePath;
  forgetPasswordForm: FormGroup;  // To store form reference
  serverErrors: any = {};
  isForgetPwdFormSubmitted: boolean = false; //form validation flag
  validMessage = validationMsg.forgetPassword;
  // display following text on modal after submit email
  emailModPopUpText = "If your email address is associated with an account, a link will be sent to you to reset your password";
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toasterService: ToastrService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,) { }

  ngOnInit() {
    this.initalizeForgetPwdForm();
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgetPasswordForm.controls; }

  //initialize forget password form
  private initalizeForgetPwdForm() {
    this.forgetPasswordForm = this.formBuilder.group({
      isTangram: [true, ''],
      email: ['', [Validators.required, Validators.pattern(this.validationService.regex.email)]],
    });
  }

  /**
   * Called when forget password form gets submitted
   * @param isForgetPwdFormValid isForgetPwdFormValid to check whether forget password form is valid or not
   */
  onSubmit(isForgetPwdFormValid) {
    this.isForgetPwdFormSubmitted = true; // Indicate that form is submitted
    if (isForgetPwdFormValid) {
      this.authService.forgetPassword(this.forgetPasswordForm.value).subscribe(() => {
        this.isForgetPwdFormSubmitted = false;
        this.activeModal.close();
        const modalRef = this.modalService.open(CommonModalComponent, { centered: true, windowClass: 'modal-small' });
        modalRef.componentInstance.data = this.emailModPopUpText;
      }, error => {
        this.serverErrors = this.validationService.getServerErrorsFromResponse(error.error.message, this.forgetPasswordForm);
        this.activeModal.close();
        const modalRef = this.modalService.open(CommonModalComponent, { centered: true, windowClass: 'modal-small' });
        modalRef.componentInstance.data = this.emailModPopUpText;
      });
    }
  }
}
