
/**
 * Global Configuration, i.e. icon path etc.
 *
 * Shared settings for all environments: dev, prod, stage etc.
 */
import { AppConfig } from '../interfaces/app-config.interface';

export const appConfig: AppConfig = {
  iconPath: 'assets/img/icons',
  imagePath: 'assets/img',
  topicPath: 'assets/img/topics',
  logoImg: "assets/img/logo.svg",
  scrollPostion: 50, // To store scroll position
  autoScrollReadableRate: 1,// To store auto scroll readable rate
  appName: 'Tangram',
  mobileHeaderHeight: 200,
  desktopHeaderHeight: 317,
  imageAllowMaxSize: 200000, // Size in Byte, 200 KB
  forumQuestionTextLimit: 300, // question max limit on forum
  dashboardCommentTextLimit: 140 // comment max limit on dashboard
};
