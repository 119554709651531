import { Router, ActivatedRoute } from '@angular/router';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgNavigatorShareService } from 'ng-navigator-share';


@Component({
  selector: 'share-tangram',
  templateUrl: './share-tangram.component.html',
  styleUrls: ['./share-tangram.component.scss']
})
export class ShareTangramComponent implements OnInit {
  imgPath = appConfig.imagePath;
  iconPath = appConfig.iconPath;
  pathwayId: string = "";
  whatsAppShareUrl: string = '';
  fbShareUrl: string = '';
  shareUrl: string = '';
  publicUrl: string = "";
  isIOs : boolean = false;
  private ngNavigatorShareService: NgNavigatorShareService;

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    ngNavigatorShareService: NgNavigatorShareService,

  ) { 
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.pathwayId = params.pathwayId;
        this.publicUrl = environment.appRedirectUrl + '/#/dashboard/' + this.pathwayId
      }
    );
    this.fbShareUrl = environment.fbShareUrl;
    this.shareUrl = this.publicUrl;
    this.isIOs = /iphone|ipad/i.test(navigator.userAgent);
    
    let details = navigator.userAgent;

    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    if (isMobileDevice) {
      this.whatsAppShareUrl=environment.whatsAppShareUrlMobile;
    } else {
      this.whatsAppShareUrl=environment.whatsAppShareUrl;
    }
  }
  
  copyToClipboard(event) {
    let element = document.getElementById("share-tangram-url");
    let elementText = element.getAttribute('value');
    this.copyText(this.publicUrl);
  }
  
  copyText(text) {
    navigator.clipboard.writeText(text);
  }

  backButton() {
    // this.location.back();
    this.router.navigate(['/dashboard', this.pathwayId]);
  }
  
  /**
 * redirects user to share popup
 * @param link to be shared
 */
  shareRedirect(appUrl,link) {
    var shareURL = appUrl + encodeURIComponent(link);
    window.open(shareURL,'popup','width=600,height=600'); 
    return false;
  }

  shareRedirectWithIOs(shareUrl){
    this.ngNavigatorShareService.share({
      title: '',
      // text: 'Sharing My tangram',
      url: shareUrl
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    })  
  }

}
