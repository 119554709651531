import { Component, OnInit, Input } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
declare let gtag: Function;
@Component({
  selector: 'tangram-upskill',
  templateUrl: './upskill.component.html',
  styleUrls: ['./upskill.component.scss']
})
export class UpskillComponent implements OnInit {

  @Input() upskill: any;
  iconPath = appConfig.iconPath;
  constructor() { }

  ngOnInit() {
  }

  /** open url on click */
  openUpskillUrl(url) {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      gtag('event', 'UpskillUrl', {
        'event_category': user._id,
        'event_label': url
      });

      // open link in new tab
      window.open(url, "_blank");
    }
  }
}
