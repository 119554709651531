import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';
import { environment } from '../../../../../environments/environment';
declare var $: any;
@Component({
  selector: 'tangram-spotlight',
  templateUrl: './spotlight.component.html',
  styleUrls: ['./spotlight.component.scss']
})
export class SpotlightComponent implements OnInit {

  @Input() spotlight: any;
  @Input() isMobile:boolean;
  backendUrl: string = "";
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.backendUrl = environment.backendUrl;   
  }


 
}
