import { Component, OnInit } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { CommonService } from 'src/app/_services/common.service';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../../services/dashboard.service';
import { AuthService } from '../../../../_services/auth.service';
declare var $: any;
@Component({
  selector: 'tangram-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  private pathwayId: string = ""; // To store pathway id
  backendUrl = environment.backendUrl;
  imagePath = appConfig.imagePath; // To store image path
  topVoices: any[];
  topActiveMembers: any[]; // To store top active members
  currentUser: any; // current user
  showFeedbackModal:boolean;
  // leaderboard tabs 
  leaderboardTabs: any[] = [
    { name: 'Most Active Members', value: 'Most Active Members' },
    { name: 'Top Voices', value: 'Top Voices' }
  ];
  currentTab: any = this.leaderboardTabs[0]; // To store current tab detail
  constructor(
    private commonService: CommonService,
    private dashboardService: DashboardService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.commonService.changeView('leaderboard');
    this.pathwayId = localStorage.getItem("pathwayId");
    this.currentUser = this.authService.currentUserValue;
    this.showTopVoices();
    this.getMostActiveMembers(); // Get top active members
  }

  /**
   * on tab change method
   * @param tab tab to be selected
   */
  onTabChange(tab) {
    this.currentTab = { ...tab };
    tab.name.toString() === this.leaderboardTabs[1].name.toString() ? this.showTopVoices : '';
  }

  /**
   * Get most active members
   */
  getMostActiveMembers() {
    this.dashboardService.getMostActiveMembers(this.pathwayId).subscribe((res: any) => {
      this.topActiveMembers = res.data;
    });
  }

  /**
   * Follow unfollow user
   * @param user user to be followed or unfollowed
   */
  followUnfollowUser(user) {
    let data = {
      "_id": user._id,
      "isFollowed": !user.isFollowed
    };
    this.dashboardService.followUnfollowUser(data).subscribe(() => {
      user.isFollowed = data.isFollowed;
    });
  }

  /** show top 10 top voices */
  showTopVoices() {
    /** retrive data pagination wise */
    let paginationDetail = {
      page: 1,
      limit: 10,
      pathwayId: this.pathwayId
    };

    this.dashboardService.getTopVoices(paginationDetail).subscribe((res: any) => {
      this.topVoices = res.data;
    });
  }

}
