import { CreateTangramComponent } from './modules/dashboard/components/create-tangram/create-tangram.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layouts/layout/layout.component';
import { AuthGuardGuard } from './_core/_guard/auth-guard.guard';
import { GuestGuard } from './_core/_guard/guest.guard';
import { UnauthorizeComponent } from './layouts/unauthorize/unauthorize.component';
import { RegisterComponent } from './modules/auth';
import { ConfigurationLayoutComponent } from './layouts/configuration-layout/configuration-layout.component';
import { EmailConfirmationComponent } from './modules/auth/components/email-confirmation/email-confirmation.component';
import { ResetPasswordComponent } from './modules/auth/components/reset-password/reset-password.component';
import { MyTangramComponent } from './modules/dashboard/components/my-tangram/my-tangram.component';

const routes: Routes = [
  //{ path: 'login', canActivate: [GuestGuard], loadChildren: () => import('./modules/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'register', component: RegisterComponent },
  { path: 'email-confirmation/:token', component: EmailConfirmationComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  //{ path: 'dashboard', component: LayoutComponent, canActivate: [AuthGuardGuard], loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'unauthorized', component: UnauthorizeComponent },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  { path: 'home', component: LayoutComponent, loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'configuration', component: ConfigurationLayoutComponent, loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule) },
  { path: 'account-management', component: LayoutComponent, loadChildren: () => import('./modules/account-management/account-management.module').then(m => m.AccountManagementModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
