import { Component, OnInit, Renderer2, ElementRef, OnDestroy, Input, Output } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { AccountManagementService } from 'src/app/modules/account-management/services/account-management.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { environment } from 'src/environments/environment';
import { CommonModalComponent } from 'src/app/shared/components/common-modal/common-modal.component';
import { DownloadModalComponent } from 'src/app/shared/components/download-modal/download-modal.component';
import { filter } from 'rxjs/operators';
import { NgNavigatorShareService } from 'ng-navigator-share';

declare let gtag: Function;
declare var $: any;
@Component({
  selector: 'tangram-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class HeaderComponent implements OnInit, OnDestroy {
  showSideMenuBar: boolean = false;
  headerFooterCommSubScription: Subscription;
  imgPath = appConfig.imagePath;
  imgEnvPath = environment.backendUrl;
  iconPath = appConfig.iconPath; // To store icon path
  isHomePage: boolean = false;
  isHomePageOnly: boolean = false;
  isHomePageSubScription: Subscription;
  currentUserSubscription: Subscription;
  isUserLoggedIn: boolean = false;
  @Output() isPageChange: DashboardComponent;
  pathwayId: string = ""; // To store pathway id
  getPathwaySubscription: Subscription;
  peopleIFollowUsers: any[];
  showFeedbackModal: boolean = false;
  publicVisibility: boolean = false; // To check id public page or not
  private ngNavigatorShareService: NgNavigatorShareService;
  constructor(
    private router: Router,
    private accountService: AccountManagementService,
    private dashboardService: DashboardService,
    private renderer: Renderer2,
    private authservice: AuthService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    ngNavigatorShareService: NgNavigatorShareService,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      gtag('event', 'page_visit', {
        'event_category': user._id,
        'event_label': this.router.url
      });
    }
  }

  ngOnInit() {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);
    if (this.router.url == '/home' && !isMobileDevice) {
      this.isHomePageOnly = true;
      $(window).scroll(function () {
        $('.top-header').addClass("th-40");
      })
    } else {
      this.isHomePageOnly = true;
      $(window).scroll(function () {
        $('.top-header').addClass("th-15");
      })
    }
    if (this.router.url == '/home/contact-us') {
      this.isHomePageOnly = false;
    }
    if (
        this.activatedRoute.snapshot.routeConfig.path == 'video/:id' 
        || this.activatedRoute.snapshot.routeConfig.path == 'dashboard/:pathwayId'
        || this.activatedRoute.snapshot.routeConfig.path == 'configuration'  
      ) {
      this.publicVisibility = true;
    }
    this.headerFooterCommSubScription = this.commonService.headerFooterComm.subscribe((param: any) => {
      this.showSideMenuBar = param.flag;
      this.toggleSideMenuBar(param.eventAction);
    });
    this.isHomePageSubScription = this.commonService.isHomeRoute.subscribe((param: boolean) => {
      this.isHomePage = param;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.router.url == '/home' && !isMobileDevice) {
        this.isHomePageOnly = true;
        $(window).scroll(function () {
          $('.top-header').addClass("th-40");
          $('.top-header').removeClass("th-15");
        })
      } else {
        this.isHomePageOnly = true;
        $(window).scroll(function () {
          $('.top-header').addClass("th-15");
          $('.top-header').removeClass("th-40");
        })
      }
      if (this.router.url == '/home/contact-us') {
        this.isHomePageOnly = false;
      }
    });

    this.currentUserSubscription = this.authservice.currentUser.subscribe((currentUser) => {
      this.isUserLoggedIn = currentUser ? true : false;
    });
    // Bind pathway id
    this.pathwayId = localStorage.getItem("pathwayId");
    this.getPathwaySubscription = this.commonService.onGetPathway.subscribe((pathwayId) => {
      if (pathwayId) {
        this.pathwayId = pathwayId;
      }
    });
    if (this.publicVisibility) {
      // sticky header js 
      $(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
          // $('.top-header').addClass("sticky");
        } else {
          // $('.top-header').removeClass("sticky");
        }
      });
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.  
    this.headerFooterCommSubScription ? this.headerFooterCommSubScription.unsubscribe() : '';
    this.currentUserSubscription ? this.currentUserSubscription.unsubscribe() : '';
    this.isHomePageSubScription ? this.isHomePageSubScription.unsubscribe() : '';
    if (this.getPathwaySubscription) {
      this.getPathwaySubscription.unsubscribe();
    }
  }

  /**
   * Toggle side menu bar
   */
  toggleSideMenuBar($event: Event) {
    $event.stopPropagation();
    this.showSideMenuBar = !this.showSideMenuBar;
    // Hide show side menu bar based on status
    if (this.showSideMenuBar) {
      this.renderer.addClass(document.body, 'menu-open');
    }
    else {
      this.renderer.removeClass(document.body, 'menu-open');
    }
  }
  /**
   * Open download app modal
   */
  openDownloadAppModal(from = 'download') {
    const modalRef = this.modalService.open(DownloadModalComponent, { centered: true, windowClass: 'modal-small' });
    if (from == 'ios') {
      // For iOS button
      modalRef.componentInstance.selectedOption = 'ios';
    } else if (from == 'android') {
      // For Android button
      modalRef.componentInstance.selectedOption = 'android';
    }
  }
  
  /**
   * Toggle side menu bar when click anywhere
   * @param event
   */
  onClick(event) {
    if (this.showSideMenuBar) {
      this.toggleSideMenuBar(event);
    } else {
      this.renderer.removeClass(document.body, 'menu-open');
    }
  }

  /**
   * Open people follow modal
   * @param modalContent modalContent to opened as modal
   */
  openPeopleFollowModal(modalContent) {
    let data = {
      page: 1,
      limit: 20
    };
    this.accountService.getPeopleUserFollow(data).subscribe((res: any) => {
      this.peopleIFollowUsers = res.data;
      this.modalService.open(modalContent, { centered: true }).result.then(() => {

      }, () => {

      });
    });
  }

  /**
   * Unfollow particular user
   * @param userData unfollow user
   */
  unFollowUser(userData) {
    userData.isFollowed = !userData.isFollowed;
    let data = {
      "_id": userData._id,
      "isFollowed": userData.isFollowed
    };
    this.dashboardService.followUnfollowUser(data).subscribe(() => {
    });

  }

  /**
   * Change view
   * @param viewName viewName to be changed
   */
  changeView(viewName) {
    this.commonService.changeView(viewName);
  }
  //logout user
  logout() {
    this.authservice.logout();
    this.router.navigate(["/home"]);
  }

  /**
  * Open feedback modal in small device
  */
  openFeedbackModalInSmallDevice() {
    this.showFeedbackModal = !this.showFeedbackModal;
    $(".feedback-sidebar").appendTo("body");
    $("#feedbackForm").modal("show");
  }

  /**
   * Go to dashboard
   */
  goToDashboard() {
    this.router.navigate(['/create-tangram']);
    this.commonService.callDashboardEvent(true);
  }

  /** 
   * Open invite referrel popup
   */
  openShareModal() {    
    const modalRef = this.modalService.open(CommonModalComponent, { centered: true});
    modalRef.componentInstance.data = "Coming Soon: Ability to invite others to the community!";
  }
}
