import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/_services/auth.service';
import * as validationMsg from "src/app/_core/_helpers/validationMsg.json";
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { AuthService as SocialAuthService } from 'angularx-social-login';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { CommonService } from '../../../_services/common.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
declare let gtag: Function;
declare var AppleID: any;
@Component({
  selector: 'tangram-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  validMessage = validationMsg.login;
  // get image path
  imgPath = appConfig;
  appleOptions: any;
  constructor(private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public authService: AuthService,
    private socialAuthService: SocialAuthService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.renderer.removeClass(document.body, 'home-page');
    this.initializeForm();
  }

  initializeForm() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}'),
      // Validators.minLength(6)]]
    });
  }

  signInwithApple() {
    //analytic tag
    gtag('event', 'loggedIn_by', {
      'event_category': 'sign_in',
      'event_label': 'facebook'
    });

    /**
    * This function will initialize the `AppleID.auth` object with parameter we pass in.
    */
    // const initApple = () => {
    AppleID.auth.init({
      clientId: `${environment.appleClientId}`, // This is the service ID we created.
      scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
      redirectURI: `${environment.appleRedirectUrl}`, // As registered along with our service ID
      state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
      usePopup: true, // Important if we want to capture the data apple sends on the client side.        
    });
    // };

    this.singInApple().then((data: any) => {
      console.log(data);
      if (data.authorization) {
        /** apple data to be send in backend */
        let appleData: any = {
          "authorization": data.authorization.id_token,
          "firstName": "",
          "lastName": "",
          "email": ""
        };

        if (data.user) {
          appleData.email = data.user.email ? data.user.email : '';
          appleData.firstName = data.user.name.firstName ? data.user.name.firstName : '';
          appleData.lastName = data.user.name.lastName ? data.user.name.lastName : '';
        }

        // Register apple user
        this.authService.registerAppleUser(appleData).subscribe((res: any) => {
          // Check if its new account then redirect to registration page for entering screen name
          if (res.data.isNewAccount) {
            // Screen name is required for the registration, so when user do social login
            // and user has not added screen name then redirect to registration page for entering screen name
            this.commonService.raiseEventToHideRegistrationControlsExceptScreenName(res.data._id);
            this.router.navigate(['/register']);
          } else {
            gtag('event', 'login_success', {
              'method_name': 'Apple',
              'login_email': appleData.email,
            });
            this.router.navigate(['/home']);
          }
        });
      } else {
        Swal.fire({
          html: 'Please try again, token expired',
          icon: 'info',
        });
      }

    }).catch((error) => {
      console.log(error);
    });


  }

  async singInApple() {
    const response = await AppleID.auth.signIn();
    return response;
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Sign in with facebook
   */
  signInWithFB(): void {
    //analytic tag
    gtag('event', 'loggedIn_by', {
      'event_category': 'sign_in',
      'event_label': 'facebook'
    });
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data) => {
      gtag('event', 'login_success', {
        'method_name': 'facebook',
        'login_email': data.email,
      });
      this.onSocialSignUP(data);
    });
  }

  /**
   * Called when social signup
   * @param data data to be registerd or logged in
   */
  onSocialSignUP(data: SocialUser) {
    let register = {
      "accountType": data.provider,
      "firstName": data.firstName,
      "lastName": data.lastName,
      "email": data.email,
      "socialId": data.id
    };

    // Register social user
    this.authService.registerSocialUser(register).subscribe((data) => {
      // Check if its new account then redirect to registration page for entering screen name
      if (data.data.isNewAccount) {
        // Screen name is required for the registration, so when user do social login
        // and user has not added screen name then redirect to registration page for entering screen name
        this.commonService.raiseEventToHideRegistrationControlsExceptScreenName(data.data._id);
        this.router.navigate(['/register']);
      } else {
        this.commonService.callDashboardEvent(true);
        if(this.route.snapshot.queryParamMap.get('onadd') && this.route.snapshot.queryParamMap.get('onadd') == "true"){
          this.router.navigate(['/create-tangram']);
        }else{
          this.router.navigate(['/home']);
        }
      }
    });
  }

  /**
   * Sign in with google
   */
  signInWithGoogle() {
    //analytic tag
    gtag('event', 'loggedIn_by', {
      'event_category': 'sign_in',
      'event_label': 'google'
    });
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
      gtag('event', 'login_success', {
        'method_name': 'Google',
        'login_email': data.email,
      });
      this.onSocialSignUP(data);
    });
  }
  //submit login form
  onSubmit() {
    //analytic tag
    gtag('event', 'loggedIn_by', {
      'event_category': 'sign_in',
      'event_label': 'normal'
    });
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    //call login api by passing email and password
    this.authService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe((res: any) => {
        // Otherwise redirect to topic selection screen       
        if (!res.success && res.message) {
          Swal.fire({
            html: res.message,
            icon: 'info',
          });
        } else {
          if(this.route.snapshot.queryParamMap.get('onadd') && this.route.snapshot.queryParamMap.get('onadd') == "true"){
            this.router.navigate(['/create-tangram']);
          }else{
            gtag('event', 'login_success', {
              'method_name': 'local',
              'login_email': this.loginForm.value.email,
            });
            this.router.navigate(['/home']);
          }

        }
        this.onReset();
      });
  }

  // reset login form
  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

  //open forget password modal
  openForgetPwdModal() {
    const modalRef = this.modalService.open(ForgotPasswordComponent, { centered: true, windowClass: 'modal-small' });
  }

  // back to home page
  backToHome() {
    this.router.navigate(['/home']);
  }
}

