import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ThumbnailSliderComponent } from './components/thumbnail-slider/thumbnail-slider.component';
import { CuratorInfoComponent } from './components/curator-info/curator-info.component';
import { ContentCompletionComponent } from './components/content-completion/content-completion.component';
import { UpskillComponent } from './components/upskill/upskill.component';
import { PlaylistComponent } from './components/playlist/playlist.component';
import { SpotlightComponent } from './components/spotlight/spotlight.component';
import { PathwayDetailComponent } from './components/pathway-detail/pathway-detail.component';
import { RatingsComponent } from './components/ratings/ratings.component';
import { VideoDetailComponent } from './components/video-detail/video-detail.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChatStreamComponent } from './components/chat-stream/chat-stream.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ForumComponent } from './components/forum/forum.component';
import { QuestionDetailComponent } from './components/question-detail/question-detail.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { MyTangramComponent } from './components/my-tangram/my-tangram.component';
import { CreateTangramComponent } from './components/create-tangram/create-tangram.component';
import { MyTangramDashboardComponent } from './components/my-tangram-dashboard/my-tangram-dashboard.component';
import { EditTangramComponent } from './components/edit-tangram/edit-tangram.component';
import { ShareTangramComponent } from './components/share-tangram/share-tangram.component';

@NgModule({
  declarations: [DashboardComponent, ThumbnailSliderComponent, CuratorInfoComponent, ContentCompletionComponent, UpskillComponent, PlaylistComponent, SpotlightComponent, PathwayDetailComponent, RatingsComponent, VideoDetailComponent, ChatStreamComponent, ForumComponent, QuestionDetailComponent, LeaderboardComponent, MyTangramComponent, CreateTangramComponent, MyTangramDashboardComponent, MyTangramDashboardComponent, EditTangramComponent, ShareTangramComponent],
  imports: [
    ReactiveFormsModule,
    SharedModule,
    DashboardRoutingModule,
    NgCircleProgressModule.forRoot({})
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class DashboardModule { }
