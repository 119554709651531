import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { CommonService } from 'src/app/_services/common.service';

declare var $;
@Component({
  selector: 'tangram-thumbnail-slider',
  templateUrl: './thumbnail-slider.component.html',
  styleUrls: ['./thumbnail-slider.component.scss']
})
export class ThumbnailSliderComponent implements OnInit {
  @Input() thumbnails: any[];
  @Input() isDashboard: boolean;
  @Output() onThumbSelect = new EventEmitter();
  @Output() onChangeDashboard = new EventEmitter();
  iconPath = appConfig.iconPath;
  previewVideoThumbnail: string = "assets/img/videos/img6.png"; // To store image url
  // slick slider configuration 
  slideConfig = {
    slidesToShow: 1, // slide to show at a time
    slidesToScroll: -1, //slide to scroll at a time
    variableWidth: true, // variable width set as per conetents
    swipeToSlide: true,
    infinite: false,
    dots: false,
    arrows: false,
    prevArrow: false,
    nextArrow: false
  };
  constructor(private commonService: CommonService) { }

  ngOnInit() { }

  /** go to dashboard page */
  goToDashboard() {
    if (!this.isDashboard) {
      // Update video content consumed percentage before go to dashboard
      // After updating percentage, dashboard will be seen.
      this.commonService.raiseEventToUpdateVideoContentConsumedPercentage();
    }
    this.onChangeDashboard.emit();
  }

  /** on thumb image -page rediredt to video dashboard */
  onThumbClick(thumbImg) {
    this.onThumbSelect.emit(thumbImg);
  }

}
