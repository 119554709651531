import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrlDashboard = `${environment.baseUrl}/dashboard`;
  baseUrlPathway = `${environment.baseUrl}/pathway`;
  baseUrlForum = `${environment.baseUrl}/forum`;
  constructor(private http: HttpClient) { }

  /**
   * Get overview detail
   */
  getOverviewDetail() {
    return this.http.get(`${this.baseUrlDashboard}/overview/details`);
  }

  /**
   * Get pathway details by Id
   */
  getPathwayDetail(pathwayId: any) {
    return this.http.get(`${this.baseUrlDashboard}/pathway/details/${pathwayId}`);
  }

  /**
   * Get pathway details by Id
   */
  updateTangramDetails(data: any) {
    return this.http.post(`${this.baseUrlPathway}/update`, data);
  }

  /**
   * Get video detail 
   */
  getVideoDetail(videoId) {
    return this.http.get(`${this.baseUrlDashboard}/video/details/${videoId}`);
  }

  /**
   * Get video detail for public page
   */
   getVideoDetailPublic(videoId) {
    return this.http.get(`${this.baseUrlDashboard}/public/video/details/${videoId}`);
  }

  /**
   * Get video comments list
   * @param videoId videoId for which video comments
   */
  getVideoCommentsList(videoData: any, type: any, visibility: any) {
    let params = type == 'all' ? new HttpParams().set("videoId", videoData.videoId) : new HttpParams().set("videoId", videoData.videoId).set("type", videoData.type);
    params = params.set("page", videoData.page).set("limit", videoData.limit)
    params = params.set("isFlagged", videoData.isFlagged).set("pathwayId", videoData.pathwayId);
    params = params.set("timeSlot", videoData.timeSlot);
    if (visibility == 'public') {
      return this.http.get(`${this.baseUrlDashboard}/public/video/comment`, { params });
    }
    return this.http.get(`${this.baseUrlDashboard}/video/comment`, { params });
  }

  /**
   * add comment with type
   * @param data 
   */
  addUserComment(data: any) {
    return this.http.post(`${this.baseUrlDashboard}/video/comment`, data);
  }

  /**
   * Reply chat comment
   * @param data data to be replied
   */
  replyChatComment(data: any) {
    return this.http.post(`${this.baseUrlDashboard}/comment/reply`, data);
  }

  /**
   * like dislike comment
   * @param data comment liked or disliked
   */
  likeDisLikeComment(data: any) {
    return this.http.post(`${this.baseUrlDashboard}/comment/likeDislike`, data);
  }

  /**
   * Like dislike video
   * @param data data to be liked or disliked
   */
  likeDislikeVideo(data: any) {
    return this.http.post(`${this.baseUrlDashboard}/video/likeDislike`, data);
  }

  /**
   * Track video watch
   * @param data data to be tracked
   */
  trackVideoWatch(data) {
    return this.http.post(`${environment.baseUrl}/dashboard/video/watch`, data);
  }

  /**
   * Get tracked video watch
   * @param videoId videoId for which video percentage
   */
  getTrackedVideoWatch(videoId) {
    return this.http.get(`${environment.baseUrl}/dashboard/video/watch/${videoId}`);
  }

  /**
   * Flag comment
   * @param comment comment to be flagged
   */
  flagComment(comment) {
    return this.http.put(`${environment.baseUrl}/dashboard/video/comment`, comment);
  }

  /**
   * Get User Detail 
   * @param userId to retrive user detail
   */
  getUserDetail(userId: any) {
    return this.http.get(`${environment.baseUrl}/users/?_id=${userId}`);
  }

  /** FORUM API */
  getForumQuestions(params: any, type) {
    let forumParams = type == 'all' ? new HttpParams().set("type", params.type).set("page", params.page).set("limit", params.limit).set("isFlagged", params.isFlagged) : new HttpParams().set("_id", params._id);
    if (params.search) {
      forumParams = forumParams.set("search", params.search);
    }
    forumParams = forumParams.set("pathwayId", params.pathwayId);
    return this.http.get(`${this.baseUrlForum}/questions`, { params: forumParams });
  }

  /**
   * Add forum question
   * @param data data
   * @param pathwayId
   */
  addForumQuestion(data, pathwayId: string) {
    data["pathwayId"] = pathwayId;
    return this.http.post(`${environment.baseUrl}/forum/questions/add`, data);
  }

  /** Get all anwers of particular question */
  getAllAnswersOfQuestion(questionId, isFlagged) {
    let answerParams = new HttpParams().set("questionId", questionId).set("isFlagged", isFlagged);
    return this.http.get(`${this.baseUrlForum}/answers`, { params: answerParams });
  }


  /** Get all anwers of particular question */
  addAnswersOfQuestion(answerDetail: any, pathwayId: string) {
    answerDetail["pathwayId"] = pathwayId;
    return this.http.post(`${this.baseUrlForum}/answers/add`, answerDetail);
  }

  /** upvote downvote and like api to update votes */
  updateUpDownLikeVoteAnswer(votes: any) {
    return this.http.post(`${this.baseUrlForum}/answers/upvotesDownvotes`, votes);
  }

  /**  forum answers flag set in question */
  flagForumAnswer(flagDetail: any) {
    return this.http.put(`${this.baseUrlForum}/answers`, flagDetail);
  }

  /**  forum question flag set in question */
  flagForumQuestion(flagDetail: any) {
    return this.http.post(`${this.baseUrlDashboard}/updateForumQuestions`, flagDetail);
  }
  /**
   * get top voices to display on leaderboard
   * @param paginationDetail get data pagianted
   */
  getTopVoices(paginationDetail) {
    let topVoiceParams = new HttpParams().set("page", paginationDetail.page).set("limit", paginationDetail.limit).set("pathwayId", paginationDetail.pathwayId);
    return this.http.get(`${environment.baseUrl}/topvoice/getTopVoice`, { params: topVoiceParams });
  }

  /**
   * Get most active members
   * @param pathwayId pathwayId for which pathway
   */
  getMostActiveMembers(pathwayId: string) {
    return this.http.get(`${environment.baseUrl}/users/getActiveMember/${pathwayId}`);
  }

  /**
   * submit feedback data
   * @param feedbackData feedback to be stored
   */
  submitUserFeedback(feedbackData: any) {
    return this.http.post(`${this.baseUrlDashboard}/postfeedback`, feedbackData);
  }


  /**
   * submit contact us data
   * @param contactUsData contact to be stored
   */
  submitContactUsForm(contactUsData: any) {
    return this.http.post(`${environment.baseUrl}/users/contactUs`, contactUsData);
  }

  /**
   * follow unfollow user from chatstream and forum
   * @param data follow/unfollow user
   */
  followUnfollowUser(data) {
    return this.http.post(`${environment.baseUrl}/users/followUnfollow`, data);
  }

  /**
   * Send email to download app
   * @param data data
   */
  sendEmailToDownloadApp(data: any) {
    return this.http.post(`${environment.baseUrl}/users/downloadAppEmail`, data);
  }

  /**
   * Store email in admin panel while download app
   * @param data data
   */
  storeEmailForDownloadApp(data: any) {
    return this.http.post(`${this.baseUrlDashboard}/postDownloadEmail`, data);
  }


  /**
   * Get video hot spot
   * @param videoId videoId
   */
  getVideoHotSpots(videoId: string, visibility: string) {
    if (visibility == 'public') {
      return this.http.get(`${environment.baseUrl}/dashboard/public/video/getVideoTimeSlotComment/${videoId}`);
    }
    return this.http.get(`${environment.baseUrl}/dashboard/video/getVideoTimeSlotComment/${videoId}`);
  }

  /**
   * Get my tangram list
   */
  getMyTangramList() {
    return this.http.get(`${environment.baseUrl}/pathway/getMyTangram`);
  }

}
