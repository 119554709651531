/**
 * This guard is used to prevent logged in user for accessing guest routes.
 * Ex: Logged in user can't access login screen.
 */
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Injectable({ providedIn: 'root' })
export class GuestGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Get current user detail
    const currentUser = this.authenticationService.currentUserValue;
    // Check if current user available then redirect to dashboard
    // Otherwise allow to access route
    if (currentUser) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
