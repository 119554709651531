let sliderOne;
let sliderTwo;
let displayValOne;
let displayValTwo;
let minGap;
let maxGap;
let sliderTrack;
let sliderMaxValue;
let percent1;
let percent2;
let strat_time_Inp;
let end_time_Inp;
let ngb_tp_hour;
let ngb_tp_min;
let ngb_tp_sec;
var durationPeriod;
let stratTime = {
  hour: 0,
  minute: 0,
  second: 0
}
let endTime = {
  hour: 0,
  minute: 0,
  second: 0
}
export default function init(duration) {  
  $(document).ready(function () {  
    if(sliderOne && sliderTwo){
      slideOne();
      slideTwo();
    }
  })
  sliderOne = document.getElementById("slider-1");
  sliderTwo = document.getElementById("slider-2");
  // displayValOne = document.getElementById("range1");
  // displayValTwo = document.getElementById("raadd nai tathtinge2");
  strat_time_Inp = document.getElementsByTagName("ngb-timepicker")[0];
  end_time_Inp = document.getElementsByTagName("ngb-timepicker")[1];
  minGap = 0;
  maxGap = 3600;
  sliderTrack = document.querySelector(".slider-track");
  durationPeriod = null
  percent1;
  percent2;
  sliderMaxValue = sliderOne ? document.getElementById("slider-1").max : '';
  // sliderMaxValue = document.getElementById("slider-1").max;
  $(sliderTwo).attr("min",minGap)
  $(sliderOne).attr("min",minGap)
  $(sliderTwo).attr("max",maxGap)
  $(sliderOne).attr("max",maxGap)
  $(sliderOne).val(minGap)
  $(sliderTwo).val(maxGap)
  $(sliderOne).attr('disabled',true)
  $(sliderTwo).attr('disabled',true)

}

export function slideOne() {
  ngb_tp_hour = jQuery(strat_time_Inp).find('.ngb-tp-input-container')[0]
  ngb_tp_min = jQuery(strat_time_Inp).find('.ngb-tp-input-container')[1]
  ngb_tp_sec = jQuery(strat_time_Inp).find('.ngb-tp-input-container')[2]
  if (parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap || parseInt(sliderTwo.value) - parseInt(sliderOne.value) >= maxGap) {
    sliderOne.value = parseInt(sliderTwo.value) - minGap;
    sliderOne.value = parseInt(sliderTwo.value) - maxGap;
  }
  let seconed_val = sliderOne.value%60
  let min_val = Math.floor(sliderOne.value/60)%60 >= 0 ? Math.floor(sliderOne.value/60)%60 : Math.floor(sliderOne.value/60)
  let hour_val =  parseInt(sliderOne.value/3600)
  hour_val = hour_val < 10 ? '0'+hour_val : hour_val
  min_val = min_val < 10 ? '0'+min_val : min_val
  seconed_val = seconed_val < 10 ? '0'+seconed_val : seconed_val
  if(durationPeriod){
    $($('.ngb-tp-second').find('input')[0]).val(seconed_val)
    $($('.ngb-tp-minute').find('input')[0]).val(min_val)
    $($('.ngb-tp-hour').find('input')[0]).val(hour_val)
  }
  stratTime = {
    hour : parseInt(hour_val),
    minute : parseInt(min_val),
    second : parseInt(seconed_val)
  }
  fillColor();
  return stratTime
}

export function slideTwo() {
  if (parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap || parseInt(sliderTwo.value) - parseInt(sliderOne.value) >= maxGap) {
    sliderTwo.value = parseInt(sliderOne.value) + minGap;
    sliderTwo.value = parseInt(sliderOne.value) + maxGap;

  }
  let seconed_val = sliderTwo.value%60
  let min_val = Math.floor(sliderTwo.value/60)%60 >= 0 ? Math.floor(sliderTwo.value/60)%60 : Math.floor(sliderTwo.value/60)
  let hour_val =  parseInt(sliderTwo.value/3600)
  hour_val = hour_val < 10 ? '0'+hour_val : hour_val
  min_val = min_val < 10 ? '0'+min_val : min_val
  seconed_val = seconed_val < 10 ? '0'+seconed_val : seconed_val

  if(durationPeriod){
    $($('.ngb-tp-second').find('input')[1]).val(seconed_val)
    $($('.ngb-tp-minute').find('input')[1]).val(min_val)
    $($('.ngb-tp-hour').find('input')[1]).val(hour_val)
  }
  endTime = {
    hour : parseInt(hour_val),
    minute : parseInt(min_val),
    second : parseInt(seconed_val)
  }
  fillColor();
  return endTime;
}

export function setDurationInit(initStrat,duration,page,totalDuration={}){
  sliderOne = document.getElementById("slider-1");
  sliderTwo = document.getElementById("slider-2");
  // displayValOne = document.getElementById("range1");
  // displayValTwo = document.getElementById("raadd nai tathtinge2");
  strat_time_Inp = document.getElementsByTagName("ngb-timepicker")[0];
  end_time_Inp = document.getElementsByTagName("ngb-timepicker")[1];
  sliderTrack = document.querySelector(".slider-track");
  sliderMaxValue = document.getElementById("slider-1").max;
  durationPeriod = duration
  let maxseconds = 0;
  let startSeconds = 0;
  let st_hour = initStrat.hour
  let st_minute = initStrat.minute
  let st_second = initStrat.second
  startSeconds += st_second
  startSeconds += (st_minute * 60)
  startSeconds += (st_hour * 60 * 60)
  if(duration){
    $($('.ngb-tp-second').find('input')[1]).val(duration.second)
    $($('.ngb-tp-minute').find('input')[1]).val(duration.minute)
    $($('.ngb-tp-hour').find('input')[1]).val(duration.hour)
  }
  if(initStrat){
    $($('.ngb-tp-second').find('input')[0]).val(st_second)
    $($('.ngb-tp-minute').find('input')[0]).val(st_minute)
    $($('.ngb-tp-hour').find('input')[0]).val(st_hour)
  }
  
  if(page == "create"){
    maxseconds = getMaxSeconds(duration)
    sliderTwo.value = maxseconds > 600 ? 600 : maxseconds
    $(sliderTwo).attr("max",maxseconds)
    $(sliderOne).attr("max",maxseconds)
    sliderMaxValue = maxseconds
  }else if(page == "edit"){
    maxseconds = getMaxSeconds(totalDuration)
    let endseconds = getMaxSeconds(duration)
    sliderTwo.value = endseconds
    if(Object.keys(totalDuration).length === 0 ){
      maxseconds = endseconds
      sliderTwo.value = maxseconds > 600 ? 600 : maxseconds
    }
    $(sliderTwo).attr("max",maxseconds)
    $(sliderOne).attr("max",maxseconds)
    sliderMaxValue = maxseconds
  }
  endTime = {...duration}
  $(sliderOne).removeAttr('disabled')
  $(sliderTwo).removeAttr('disabled')
  sliderOne.value = startSeconds
  maxGap = 600;
  minGap = 0;
  fillColor()
}

function fillColor() {
  let bgGradient;
  if(durationPeriod){
    percent1 = (sliderOne.value / sliderMaxValue) * 100;
    percent2 = (sliderTwo.value / sliderMaxValue) * 100;
    // console.log(percent1,percent2,sliderOne.value,sliderTwo.value,sliderMaxValue)
    bgGradient = `linear-gradient(to right, #dadae5 ${percent1}% , #D54ED2 ${percent1}% , #D54ED2 ${percent2}%, #dadae5 ${percent2}%)`
  }else{
    percent1 = (sliderOne.value / sliderMaxValue) * 100;
    percent2 = (sliderTwo.value / sliderMaxValue) * 100;
    bgGradient = `linear-gradient(to right, #dadae5 ${percent1}% , #4d4d4e ${percent1}% , #4d4d4e ${percent2}%, #4d4d4e ${percent2}%)`
  }
  sliderTrack.style.background = bgGradient;
}

function getMaxSeconds(duration){
  let totalSeconeds = 0
  let {hour,minute,second} = duration
  totalSeconeds = second
  totalSeconeds += (minute * 60)
  totalSeconeds += (hour * 60 * 60)
  return totalSeconeds
}

export function resetparameters(){
  sliderOne = document.getElementById("slider-1");
  sliderTwo = document.getElementById("slider-2");
  // displayValOne = document.getElementById("range1");
  // displayValTwo = document.getElementById("raadd nai tathtinge2");
  strat_time_Inp = document.getElementsByTagName("ngb-timepicker")[0];
  end_time_Inp = document.getElementsByTagName("ngb-timepicker")[1];
  minGap = 0;
  maxGap = 3600;
  sliderTrack = document.querySelector(".slider-track");
  percent1;
  percent2;
  $(sliderTwo).attr("max",maxGap)
  $(sliderOne).attr("max",maxGap)
  sliderMaxValue = document.getElementById("slider-1").max;
  sliderOne.value = minGap;
  sliderTwo.value = maxGap;
  $(sliderOne).attr('disabled',true)
  $(sliderTwo).attr('disabled',true)
  percent1 = (sliderOne.value / sliderMaxValue) * 100;
  percent2 = (sliderTwo.value / sliderMaxValue) * 100;
  let bgGradient = `linear-gradient(to right, #dadae5 ${percent1}% , #4d4d4e ${percent1}% , #4d4d4e ${percent2}%, #4d4d4e ${percent2}%)`
  sliderTrack.style.background = bgGradient;

}
