import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountManagementService {

  constructor(private http: HttpClient) {
  }

  /**
   * Get Edit Profile
   */
  getEditProfile() {
    return this.http.get(`${environment.baseUrl}/users/getEditProfile`);
  }

  /**
   * save edit profile
   * @param profileData to be save in db
   */
  saveEditProfile(profileData) {
    return this.http.post(`${environment.baseUrl}/users/editProfile`, profileData);
  }

  /**
   * Retrive user list which followed by current user
   * @param data get data paginated
   */
  getPeopleUserFollow(data) {
    let followUserParams = new HttpParams().set("page", data.page).set("limit", data.limit)
    return this.http.get<any>(`${environment.baseUrl}/users/getFollowUser`, { params: followUserParams });
  }
}
