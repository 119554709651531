import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { appConfig } from '../../../../_core/config/app-config.const';
import { CommonService } from '../../../../_services/common.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../../services/dashboard.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

import { FlagConfirmationModalComponent } from '../../../../shared/components/flag-confirmation-modal/flag-confirmation-modal.component';
declare let gtag: Function;
@Component({
  selector: 'tangram-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit, OnDestroy {
  appConfig = appConfig;
  appName = appConfig.appName; //To display when user role is admin 
  iconPath: string = appConfig.iconPath; // To store icon path from config
  showFeedbackModal: boolean = false; // To show hide feedback modal
  @ViewChild('cardBody', { static: true }) cardBody: ElementRef; // To store card body reference
  @ViewChild("askOrSearchQuestionModal", { static: true }) askOrSearchQuestionModal: TemplateRef<any>;
  askOrSearchQuestionModalSubscription: Subscription;
  paginationDetail = {
    page: 1,
    limit: 10
  }
  forumForm: FormGroup; // To represent forum form
  forumList: any[] = []; // To store forum list
  forumMobileList: any[] = []; //To store data in to list in mobiel device
  forumCommunityMember: any; // To display community members
  forumCommentsLeft: any; // To display how many comments are posted
  currentUser: any;
  forumQuestionModalRef: NgbModalRef; //modal reference
  screenHeight: number;
  screenWidth: number;
  autoScrollInterval: any; // To store auto scroll interval reference
  autoScrollReadableRate = appConfig.autoScrollReadableRate; // To store auto scroll readable rate
  scrollPositionToLoadData = appConfig.scrollPostion; // scroll position to call api
  isPageScrollDisabled: boolean = true; // To enable disable pagination on scroll
  totalPages: any;
  calculatedHeight: number;
  searchTextChangedSubscription: Subscription;
  pathwayId: string = ""; // To store pathway id
  questionList: any[]; // To store all anwers
  constructor(
    private commonService: CommonService,
    private route: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute
  ) {
    let user=JSON.parse(localStorage.getItem('currentUser'));
    if(user){gtag('event','page_visit', {
      'event_category': user._id,
      'event_label': '/forum'
    });}
    this.pathwayId = this.activatedRoute.snapshot.params["pathwayId"];
  }

  /**
   * Called when component being called
   */
  ngOnInit() {
    this.commonService.changeView('forum');
    this.handleAskOrSearchQuestionModalSubscription();
    // Build forum form
    this.buildForumForm();
    this.getForumQuestionsList();
    this.getScreenSize();
    this.autoScrollInterval = setInterval(() => { this.autoChatStreamScroll() }, 100);
  }

  /**
  * Auto chat stream scroll
  */
  autoChatStreamScroll() {
    // Save current tab scroll position, so it can be set when tab selected again
    // this.tabScrollPosition[this.currentTab.container] = this.cardBody.nativeElement.scrollTop;
    this.cardBody.nativeElement.scrollBy(0, this.autoScrollReadableRate);
  }

  /**
   * Called when mouse enter on chat stream body
   */
  onMouseEnterOnChatStreamBody() {
    this.stopAutoScrollChatStream();
  }

  /**
   * Called when mouse leave from chat stream body
   */
  onMouseLeaveOnChatStreamBody() {
    this.startAutoScrollChatStream();
  }

  /**
   * Start auto scroll chat stream
   */
  startAutoScrollChatStream() {
    // Set interval again
    this.autoScrollInterval = setInterval(() => { this.autoChatStreamScroll() }, 100);
  }

  /**
   * Stop auto scroll chat stream
   */
  stopAutoScrollChatStream() {
    // If auto scroll is being performed then stop it
    if (this.autoScrollInterval) {
      clearInterval(this.autoScrollInterval);
    }
  }

  onScroll() {
    let scrollTop = (<HTMLElement>this.cardBody.nativeElement).scrollTop;
    let scrollHeight = (<HTMLElement>this.cardBody.nativeElement).scrollHeight;
    let offsetHeight = (<HTMLElement>this.cardBody.nativeElement).offsetHeight;

    if ((scrollTop + offsetHeight) >= scrollHeight - this.scrollPositionToLoadData && !this.isPageScrollDisabled) {
      // Check if total page is greater then equal to current page number
      // then get new chat messages by that page number      
      this.paginationDetail.page += 1;
      if (this.totalPages >= this.paginationDetail.page) {
        //Set next page number 
        this.isPageScrollDisabled = true;
        this.getForumQuestionsList(this.forumForm.value.question);
      }
    }
    // Reset scrollbar position to top when it reaches bottom
    if ((scrollTop + offsetHeight) >= scrollHeight && !this.isPageScrollDisabled) {
      this.cardBody.nativeElement.scrollTop = 0;
    }
  }

  /**
   * get sceen size to set scroll bar
   */
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    /** check device is mobile or desktop */
    if (this.commonService.checkDeviceIsMObileOrNot) {
      // true for mobile device      
      this.calculatedHeight = this.screenHeight - appConfig.mobileHeaderHeight;
    } else {
      // false for not mobile device
      this.calculatedHeight = this.screenHeight - appConfig.desktopHeaderHeight;
    }

  }

  /** Get forum questions list */
  getForumQuestionsList(searchText?) {
    this.isPageScrollDisabled = true;
    let forumParams: any = {
      type: 'QUESTION',
      page: this.paginationDetail.page,
      limit: this.paginationDetail.limit,
      isFlagged: false,
      pathwayId: this.pathwayId
    }
    //if search filter is enabled
    if (searchText) {
      forumParams.search = searchText;
    }
    this.dashboardService.getForumQuestions(forumParams, 'all').subscribe((res: any) => {
      this.currentUser = this.authService.currentUserValue;
      if (res.questionCount < forumParams.limit) {
        this.totalPages = 1;
      } else {
        let totalPage: number = res.questionCount / forumParams.limit;
        this.totalPages = (Math.floor(totalPage));
        let pageReminder = res.questionCount % forumParams.limit;
        this.totalPages += pageReminder == 0 ? 0 : 1;
      }

      if (searchText) {
        if (forumParams.page == 1) {
          this.forumList = res.data;
        } else {
          this.forumList = [...this.forumList, ...res.data];
        }
      } else {
        if (forumParams.page == 1) {
          this.forumList = res.data;
        } else {
          this.forumList = [...this.forumList, ...res.data];
        }
      }


      this.forumCommunityMember = res.memberCount;
      this.forumCommentsLeft = res.answerCount;
      this.isPageScrollDisabled = false;
    });
  }

  /**
   * Build forum form
   */
  buildForumForm() {
    this.forumForm = this.formBuilder.group({
      type: ['QUESTION', ''],
      question: ['', Validators.required]
    });

    // Called when search gets changed
    this.searchTextChangedSubscription = this.forumForm.controls['question'].valueChanges.pipe(debounceTime(1000))
      .subscribe(newSearchText => {
        /** check mobile device or not */
        if (this.commonService.checkDeviceIsMObileOrNot()) {
          if (newSearchText) {
            // Search text in mobile device
            this.getForumQuestionsListBySearchTextMobile(newSearchText);
          } else {
            this.forumMobileList = [];
          }
        } else {
          // Search text in desktop
          this.isPageScrollDisabled = true;
          this.paginationDetail.page = 1;
          this.getForumQuestionsList(newSearchText);
        }

      });
  }


  /**
   * Call function when device is mobile
   * @param newSearchText search text
   */
  getForumQuestionsListBySearchTextMobile(newSearchText: any) {
    this.isPageScrollDisabled = true;
    let forumParams: any = {
      type: 'QUESTION',
      isFlagged: false,
      search: newSearchText,
      pathwayId: this.pathwayId
    };
    this.dashboardService.getForumQuestions(forumParams, 'all').subscribe((res: any) => {
      this.forumMobileList = res.data;
    });
  }

  /**
   * Handle ask or search question modal subscription
   */
  handleAskOrSearchQuestionModalSubscription() {
    // Receive event and check request for open ask or search question modal then open it
    this.askOrSearchQuestionModalSubscription = this.commonService.openAskOrSearchQuestionModal.subscribe((isOpen: boolean) => {

      if (isOpen) {
        this.forumQuestionModalRef = this.modalService.open(this.askOrSearchQuestionModal, { centered: true, scrollable: true });
        this.forumQuestionModalRef.result.then(() => {
        }, () => {
        });
      }

    });
  }

  redirectToQuestion(questionId) {
    this.forumQuestionModalRef ? this.forumQuestionModalRef.close('') : "";
    this.route.navigate(['dashboard/forum/' + this.pathwayId + '/question-detail/' + questionId]);
  }

  /**
   * Called when form gets submitted
   * @param isForumFormValid isForumFormValid to check whether form is valid or not
   */
  onQuestionSubmit(isForumFormValid: boolean) {
    if (isForumFormValid) {
      this.dashboardService.addForumQuestion(this.forumForm.value, this.pathwayId).subscribe((res: any) => {
        let user=JSON.parse(localStorage.getItem('currentUser'));
        if(user){
        gtag('event',  'forum_comment_type', {
          'event_category': user._id,
          'event_label': this.forumForm.value.type
        })
    
        gtag('event',  this.pathwayId, {
          'event_category': user._id,
          'event_label': this.forumForm.value.question
        })
      };
        this.forumList.unshift({
          "type": this.forumForm.value.type,
          "question": this.forumForm.value.question,
          "userId": this.currentUser._id,
          "updatedAt": new Date(),
          "user": {
            "role": this.currentUser.role,
            "_id": this.currentUser._id,
            "screenName": this.currentUser.screenName,
            "firstName": this.currentUser.firstName,
            "lastName": this.currentUser.lastName,
          },
          "id": res.questionId,
          "answercount": 0
        })
        this.forumForm.reset();
        this.buildForumForm();
        this.forumQuestionModalRef ? this.forumQuestionModalRef.close('') : "";
      });
    }
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up ask or search question modal subscription
    if (this.askOrSearchQuestionModalSubscription) {
      this.askOrSearchQuestionModalSubscription.unsubscribe();
    }

    if (this.searchTextChangedSubscription) {
      this.searchTextChangedSubscription.unsubscribe();
    }
  }

   /**
   * Open flag confirmation modal
   * @param questionId questionId to be flagged
   */
    openFlagConfirmationModal(questionId) {
      const flagConfirmModalRef = this.modalService.open(FlagConfirmationModalComponent, { centered: true });
      flagConfirmModalRef.result.then((result) => {
        this.flagQuestion(questionId);
      }, () => {
      });
    }

    
  // flag question
  flagQuestion(questionId) {
    let data = {
      _id: questionId,
    //  "pathwayId": this.pathwayId,
      isFlag: true,
      isArchive:false,
    }
    this.dashboardService.flagForumQuestion(data).subscribe(() => {
      // Remove answer from the list        

      let index = this.forumList.findIndex((qus) => { return qus.id == questionId });
      if (index != -1) {
        this.forumList.splice(index, 1);
      }
    });
  }
}
