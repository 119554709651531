import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RegisterComponent, ForgotPasswordComponent, EmailConfirmationComponent, ResetPasswordComponent],
  imports: [
    SharedModule,
    RouterModule,
    NgSelectModule,    
  ],
  entryComponents: [ForgotPasswordComponent]
})
export class AuthModule { }
