import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '../../../../_core/services/validation.service';
import { AuthService } from '../../../../_services/auth.service';
import { appConfig } from '../../../../_core/config/app-config.const';

@Component({
  selector: 'tangram-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  // get image path
  imgPath = appConfig;
  token: string = ""; // To store token
  resetPasswordForm: FormGroup; // To store form reference
  resetPasswordFormValidationMessages = { // To store reset password form validation
    'newPassword': {
      'required': 'New Password should not be empty.',
      'pattern': ''
    },
    'reEnterPassword': {
      'required': 'Re-Enter Password should not be empty.',
      'compare': 'New password & re-enter password mismatches'
    }
  };
  resetPasswordFormErrors = {
    newPassword:'',
    reEnterPassword:''
  }; // To store errors
  isResetPasswordFormSubmitted: boolean = false; // To store status about reset password form submission
  resetPasswordFormValueChangesSubscription: Subscription;
  newPasswordPatternValidationMessages: string[] = []; // To store new password validation messages

  constructor(
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router) {
    this.token = this.activatedRoute.snapshot.params["token"];
  }

  /**
   * Called when comopnent being initialized
   */
  ngOnInit() {
    this.buildResetPasswordForm();
  }

  /** Build reset password form */
  private buildResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern(this._validation.regex.password)]],
      reEnterPassword: ['', Validators.required]
    },
      { validators: this._validation.comparePassword("newPassword", "reEnterPassword") }
    );

    // Get validation errors
    this.getValidationErrors();

    // Get validation error when reset password form values get changed
    this.resetPasswordFormValueChangesSubscription = this.resetPasswordForm.valueChanges.subscribe((resetPassword) => {
      // If new password is available then check its value contains specific pattern or not
      if (resetPassword.newPassword != "" && resetPassword.newPassword != undefined)
        this.newPasswordPatternValidationMessages = this._validation.getPasswordPatternValidationMessages(resetPassword.newPassword);
      else
        this.newPasswordPatternValidationMessages = [];
      this.getValidationErrors()
    });
  }

  // Get validation errors
  getValidationErrors() {
    this.resetPasswordFormErrors = this._validation.getValidationErrors(this.resetPasswordForm, this.resetPasswordFormValidationMessages);
  }

  /**
   * Called when reset password form is submitted
   * @param isResetPasswordFormValid isResetPasswordFormValid to check whether reset password form is valid or not
   */
  onSubmit(isResetPasswordFormValid) {
    this.isResetPasswordFormSubmitted = true; // Indicate that form is submitted

    // Reset password if reset password form is valid
    if (isResetPasswordFormValid) {
      this.authService.resetPassword(this.resetPasswordForm.value.newPassword, this.token).subscribe((res) => {
        // Redirect to login page after reset password
        this.router.navigate(['login']);
      });
    }

  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up reset password form value changes subscription
    if (this.resetPasswordFormValueChangesSubscription) {
      this.resetPasswordFormValueChangesSubscription.unsubscribe();
    }
  }

}
