import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../../_core/services/validation.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../../modules/dashboard/services/dashboard.service';
declare let gtag: Function;

@Component({
  selector: 'tangram-download-modal',
  templateUrl: './download-modal.component.html',
  styleUrls: ['./download-modal.component.scss']
})
export class DownloadModalComponent implements OnInit, OnDestroy {
  iconPath = appConfig.iconPath;
  imgPath = appConfig.imagePath;
  //select option download type either android or ios
  downloadTypeList: any[] = [
    { name: 'Android', value: 'android' },
    { name: 'iOS', value: 'ios' }
  ];
  selectedOption: string;
  downloadAppForm: FormGroup; // To represent download app form
  isDownloadAppFormSubmitted: boolean = false; // To store status about form submission
  downloadAppFormValueChangesSubscription: Subscription;
  downloadAppFormValidationMessages = { // To store download app form validation
    'device': {
      'required': 'Device should be selected.',
    },
    'email': {
      'required': 'Email should not be empty.',
      'pattern': 'Email must contain @, domain name, .(dot)'
    }
  };
  downloadAppFormErrors = {
    device:'',
    modal:'',
    email:''
  }; // To store errors
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private validationService: ValidationService,
    private dashboardService: DashboardService) { }

  ngOnInit() {
    this.buildDownloadAppForm();
  }

  /**
   * Build download app form
   */
  buildDownloadAppForm() {
    this.downloadAppForm = this.formBuilder.group({
      device: [null, Validators.required],
      email: ["", [Validators.required, Validators.pattern(this.validationService.regex.email)]]
    });

    this.downloadAppForm.controls['device'].setValue(this.selectedOption);

    // Get validation errors
    this.getValidationErrors();

    // Get validation error when reset password form values get changed
    this.downloadAppFormValueChangesSubscription = this.downloadAppForm.valueChanges.subscribe(() => {
      this.getValidationErrors()
    });
  }
  /**
   * Open download app modal
   */
  openDownloadAppModal(from = 'download') {
    const modalRef = this.modalService.open(DownloadModalComponent, { centered: true, windowClass: 'modal-small' });
    if (from == 'ios') {
      // For iOS button
      modalRef.componentInstance.selectedOption = 'ios';
    } else if (from == 'android') {
      // For Android button
      modalRef.componentInstance.selectedOption = 'android';
    }
  }

  // Get validation errors
  getValidationErrors() {
    this.downloadAppFormErrors = this.validationService.getValidationErrors(this.downloadAppForm, this.downloadAppFormValidationMessages);
  }

  /**
   * Called when form gets submitted
   * @param isFormSubmitted isFormSubmitted to check whether form is valid or not
   */
  onSubmit(isFormSubmitted) {
    this.isDownloadAppFormSubmitted = true;
    let { device, ...storeEmailData} = this.downloadAppForm.value
    storeEmailData['type']  = device
    if (isFormSubmitted) {
      this.dashboardService.sendEmailToDownloadApp(this.downloadAppForm.value).subscribe(() => {
        gtag('event', 'app_download', {
          'email': this.downloadAppForm.value.email
        });
        this.activeModal.close("");
      });
      this.dashboardService.storeEmailForDownloadApp(storeEmailData).subscribe()
    }
  }

  // Called when component being destroyed
  ngOnDestroy() {
    if (this.downloadAppFormValueChangesSubscription) {
      this.downloadAppFormValueChangesSubscription.unsubscribe();
    }
  }

}
