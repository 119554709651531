import { ValidationService } from 'src/app/_core/services/validation.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'tangram-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  hideModal: boolean = false;
  submitted: boolean = false;
  feedbackForm: FormGroup; // To represent feedback form
  currentUser: any;
  visibility: string = ""; // To check id public page or not
  feedbackFormValidationMessages = { // To store reset password form validation
    'message': {
      'required': 'Feedback Message should not be empty.',
    },
    'email': {
      'required': 'Email must contain @, domain name, .(dot)',
    },
  };
  feedbackFormErrors = {
    message: '',
    email: '',
  };
  
  constructor(
    private formBuilder: FormBuilder,
    private dashboarService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private _validation: ValidationService,
    private router: Router
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    if (this.activatedRoute.parent.snapshot.routeConfig.path == 'video/:id') {
      this.visibility = 'public';
    }
    this.buildFeedbackForm();
    // Disable/Enable Email input based on checkbox
    this.feedbackForm.get('isOptForNotification').valueChanges
      .subscribe(value => {
        if (value) {
          // disable the input when new value is true
          this.feedbackForm.controls['email'].enable();
          this.feedbackForm.controls['email'].setValidators([Validators.required, Validators.pattern(this._validation.regex.email)]);
        } else {
          // (re-)enable the input when new value is false
          this.feedbackForm.controls['email'].disable();
          this.feedbackForm.controls['email'].clearValidators();
          this.feedbackForm.controls['email'].reset();
        }
        this.feedbackForm.controls['email'].updateValueAndValidity();
      })
    
  }

  /**
  * Build feedback form
  */
  buildFeedbackForm() {
    this.feedbackForm = this.formBuilder.group({
      // fullname: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.pattern(this._validation.regex.email)]],
      message: ['', [Validators.required]],
      isOptForNotification: false
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.feedbackForm.controls; }

  // Get validation errors
  getValidationErrors() {
    this.feedbackFormErrors = this._validation.getValidationErrors(this.feedbackForm, this.feedbackFormValidationMessages);
  }

  /**
   * Submit feedback
   * @param isFeedbackValid check feedback is valid 
   */
  submitFeedback(isFeedbackValid: boolean) {
    if (this.currentUser || this.visibility !== 'public') {
      this.submitted = true;
      if (isFeedbackValid) {
        if (this.feedbackForm.value.isOptForNotification == null) {
          this.feedbackForm.value.isOptForNotification = false;
        }
        this.dashboarService.submitUserFeedback(this.feedbackForm.value).subscribe((res) => {
          this.submitted = false;
          this.feedbackForm.reset();
          this.hideModal = true;
          $("#feedbackForm").modal("hide");
          $(".feedback-sidebar").removeClass("feedback-open")
        });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
}
