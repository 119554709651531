import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CreateTangramService {

  youtubeAPI: string = 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet&part=contentDetails'; // To store youtube api
  constructor(private http: HttpClient) { }


  /**
   * Get youtube video detail
   * @param videoId videoId for which video
   */
  getYoutubeVideoDetail(videoId: string) {
    let params = new HttpParams().set("hideHeader", "true").set('interceptorNotNeeded', 'true');
    return this.http.get(`${this.youtubeAPI}&id=${videoId}&key=${environment.youtubeSiteKey}`, { params: params });
  }
  /**
   * Get spotify url detail
   * @param url url of spotify
   */
  getSpotifyUrlDetail(url: string) {
    return this.http.post(`${environment.baseUrl}/pathway/getSpotifyLinkInfo`, { spotifyLink: url });
  }

  /**
   * Get google workspace detail
   * @param data url of spotify
   */
  getGoogleWorkspaceDetails(data: Object) {
    return this.http.post(`${environment.baseUrl}/pathway/getWorkspaceInfo`, data);
  }
  
  /**
   * Save video
   * @param video video to be saved
   */
  saveVideo(video) {
    return this.http.post(`${environment.baseUrl}/pathway/video/createUpdate`, video);
  }

  /**
   * Get video detail 
   */
  getVideoDetail(videoId) {
    return this.http.get(`${environment.baseUrl}/dashboard/video/details/${videoId}`);
  }
}