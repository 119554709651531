import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { appConfig } from '../../../../_core/config/app-config.const';
import { FlagConfirmationModalComponent } from '../../../../shared/components/flag-confirmation-modal/flag-confirmation-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CommonService } from 'src/app/_services/common.service';
import { Subscription } from 'rxjs';
import { SortService } from 'src/app/shared/services/sort.service';

@Component({
  selector: 'tangram-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit, OnDestroy {
  @ViewChild("addCommentModal", { static: true }) addCommentModal: TemplateRef<any>;
  iconPath: string = appConfig.iconPath; // To store icon path from config
  appName = appConfig.appName; //To display when user role is admin 
  answerForumForm: FormGroup; // To represent answer forum form
  questionId: string; // To store question id
  answersList: any[]; // To store all anwers
  totalCount: any; // totoal answers posted on question
  currentUser: any; // current user
  questionDetail: any = {
    question: '',
    user: { screenName: '' },
    updatedAt: ''
  };
  addCommentModalSubscription: Subscription;
  forumAnswerModalRef: NgbModalRef; //modal reference
  pathwayId: string = ""; // To store pathway id
  constructor(
    private sortService: SortService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.pathwayId = this.route.snapshot.params["pathwayId"];
  }

  ngOnInit() {
    this.handleAddCommentModalSubscription();
    this.commonService.changeView('question-detail');
    this.route.params.subscribe((data) => {
      this.questionId = data.id;
    });
    this.intializeAnswerForm();
    this.getForumQuestionsDetails();
    this.getAllAnswersOfQuestion();
  }

  /**
   * initialize anwer form to post answer
   */
  intializeAnswerForm() {
    this.answerForumForm = this.formBuilder.group({
      questionId: [this.questionId, ''],
      answer: ['', Validators.required]
    });
  }


  /**
 * Handle add comment modal subscription
 */
  handleAddCommentModalSubscription() {
    // Receive event and check request for open ask or search question modal then open it
    this.addCommentModalSubscription = this.commonService.openAskOrSearchQuestionModal.subscribe((isOpen: boolean) => {
      if (isOpen) {
        this.forumAnswerModalRef = this.modalService.open(this.addCommentModal, { centered: true });
        this.forumAnswerModalRef.result.then(() => {
        }, () => {
        });
      }
    });
  }



  /** Get forum questions list */
  getForumQuestionsDetails() {
    let forumParams: any = {
      _id: this.questionId,
      pathwayId: this.pathwayId
    }
    // get question detail
    this.dashboardService.getForumQuestions(forumParams, 'detail').subscribe((res: any) => {
      if (res.data.length) {
        this.questionDetail = res.data[0];
      }
    });
  }

  /** toggle read more and read less activity */
  toggleReadMoreLess(index) {
    // toggle read more on particular record    
    this.answersList[index].showShortDesciption = !this.answersList[index].showShortDesciption;
  }

  /** retrieve all answer of particular question */
  getAllAnswersOfQuestion() {
    this.dashboardService.getAllAnswersOfQuestion(this.questionId, false).subscribe((res: any) => {
      this.currentUser = this.authService.currentUserValue;
      res.data.map(x => x.showShortDesciption = true);
      this.answersList = res.data;
      this.totalCount = res.totalCount;
    });
  }

  /**
   * Called when comment gets submitted
   * @param data data to be saved
   */
  onAnswerSubmit(isAnswerFormValid: boolean) {
    if (isAnswerFormValid) {
      this.dashboardService.addAnswersOfQuestion(this.answerForumForm.value, this.pathwayId).subscribe((res: any) => {
        this.answersList.unshift({
          "questionId": this.questionId,
          "userId": this.currentUser._id,
          "answer": this.answerForumForm.value.answer,
          "updatedAt": new Date(),
          "showShortDesciption": true,
          "user": {
            "role": this.currentUser.role,
            "_id": this.currentUser._id,
            "screenName": this.currentUser.screenName,
            "firstName": this.currentUser.firstName,
            "lastName": this.currentUser.lastName,
          },
          "likes": 0,
          "upvotes": 0,
          "downvotes": 0,
          "isLiked": false,
          "isUpvoted": false,
          "isDownvoted": false,
          "_id": res.answerId
        });
        this.answerForumForm.patchValue({
          answer: ''
        });
        this.forumAnswerModalRef ? this.forumAnswerModalRef.close('') : '';
      });
    }
  }

  /** up down and like vote on answer */
  upDownLikeVoteAnswer(commentStatus, answer, type) {
    let data: any = {
      "answerId": answer._id,
      "pathwayId": this.pathwayId
    };
    let statusToChange = commentStatus ? null : true; // status to be change
    if (type == 'up') {
      data.isUpVote = statusToChange;
    } else if (type == 'down') {
      data.isDownVote = statusToChange;
    } else {
      data.isLiked = statusToChange;
    }

    this.dashboardService.updateUpDownLikeVoteAnswer(data).subscribe((res) => {
      if (type == 'up') {
        answer.isUpvoted = data.isUpVote;
        // Update upvotes if user upvote it and remove upvote if user downvote it
        answer.upvotes += data.isUpVote ? 1 : -1;
        // If user upvoted but downvote exists then remove downvote
        if (answer.isDownvoted) {
          answer.isDownVote = false;
          answer.downvotes -= 1;
        }
      } else if (type == 'down') {
        answer.isDownvoted = data.isDownVote;
        // Update downvote if user downvote it and remove downvote if user upnvote it
        answer.downvotes += data.isDownVote ? 1 : -1;
        // If user downvoted but upvote exists then remove upvote
        if (answer.isUpvoted) {
          answer.isUpvoted = false;
          answer.upvotes -= 1;
        }
      } else {
        answer.isLiked = data.isLiked;
        // Update likes if user liked it and remove likes if user unliked it
        answer.likes += data.isLiked ? 1 : -1;
      }

      //sort array by upvotes
      this.sortService.sortArray(this.answersList, 'upvotes', false);

    });
  }

  /**
   * Open flag confirmation modal
   * @param questionId questionId to be flagged
   */
  openFlagConfirmationModal(answerId) {
    const flagConfirmModalRef = this.modalService.open(FlagConfirmationModalComponent, { centered: true });
    flagConfirmModalRef.result.then((result) => {
      this.flagAnswer(answerId);
    }, () => {
    });
  }

  // flag answer
  flagAnswer(answerId) {
    let data = {
      "_id": answerId,
      "pathwayId": this.pathwayId,
      "isFlagged": true
    }
    this.dashboardService.flagForumAnswer(data).subscribe(() => {
      // Remove answer from the list
      let index = this.answersList.findIndex((ans) => { return ans._id == answerId });
      if (index != -1) {
        this.answersList.splice(index, 1);
      }
    });
  }

  followUnfollowUser(userData) {
    if (userData._id != this.currentUser._id) {
      let data = {
        "_id": userData._id,
        "isFollowed": !userData.isFollowed
      };
      this.dashboardService.followUnfollowUser(data).subscribe(() => {
        userData.isFollowed = data.isFollowed;
      });
    }
  }
  ngOnDestroy() {
    //clean up modal subscription
    this.addCommentModalSubscription ? this.addCommentModalSubscription.unsubscribe() : '';
  }

}
