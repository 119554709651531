import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_model/models';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public loginUrl = environment.baseUrl;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Save logged in user
   * @param user
   */
  saveLoggedInUser(user: any) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', JSON.stringify(user.data));
    this.currentUserSubject.next(user.data);
  }

  /**
   * Register Apple user
   * @param data data to be registered if new
   */
  registerAppleUser(data: any) {
    return this.http.post(`${this.loginUrl}/users/appleAuth`, data)
      .pipe(map((user: any) => { 
        this.saveLoggedInUser(user);
        return user;
      }));
  }
  /**
   * Register social user
   * @param data data to be registered
   */
  registerSocialUser(data: any) {
    return this.http.post(`${this.loginUrl}/users/socialAccount`, data)
      .pipe(map((user: any) => {
        this.saveLoggedInUser(user);
        return user;
      }));
  }

  /**
   * Register user
   * @param register register to be saved
   */
  registerUser(register) {
    return this.http.post(`${environment.baseUrl}/users/signup`, register);
  }

  /**
   * Verify recaptcha v3 token
   * @param token token to be verified
   */
  verifyRecaptchaV3Token(token: string) {
    return this.http.get(`${environment.baseUrl}/recaptcha/verify/${token}`);
  }

  /**
   * Login api call
   * @param email email to be verfied user
   * @param password password to be verfied user
   */
  login(email: string, password: string) {
    return this.http.post<User>(`${this.loginUrl}/users/login`, { email, password })
      .pipe(map((user: any) => {
        if (user.success) {
          this.saveLoggedInUser(user);
        }
        return user;
      }));
  }

  /**
   * Forget password api
   * @param email to check user exists or not and send link to email
   */
  forgetPassword(email: any) {
    return this.http.post<any>(`${environment.baseUrl}/users/forgotPassword`, email);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  /**
   * Update user detail
   * @param userId 
   * @param data data to be updated
   */
  updateUserDetail(userId: string, data: any) {
    return this.http.put<any>(`${environment.baseUrl}/users/${userId}`, data);
  }

  /**
   * Verify email address
   * @param token token to be verified
   */
  verifyEmail(token: string) {
    return this.http.post(`${environment.baseUrl}/users/verifyEmail?token=${token}`, {});
  }

  /**
   * Resend verification email
   * @param userId
   */
  resendVerificationEmail(userId: string) {
    return this.http.post(`${environment.baseUrl}/users/resendVerifyEmail`, { _id: userId });
  }

  /**
   * Reset password
   * @param password password to be changed
   * @param token token to be identified for which users password should be reset
   */
  resetPassword(password: string, token: string) {
    return this.http.post<any>(`${environment.baseUrl}/users/resetPassword?token=${token}`, { password });
  }

  /**
   * Verify user
   * @param userId userId to be verified
   */
  verifyUser(userId: string) {
    return this.http.post<any>(`${environment.baseUrl}/users/verifyUser`, { _id: userId }).pipe(map((user: any) => {
      if (user.success) {
        this.saveLoggedInUser(user);
      }
      return user;
    }));
  }

  /**
   * Change password
   * @param currentPassword currentPassword to be verified
   * @param newPassword newPassword to be set
   */
  changePassword(currentPassword: string, newPassword: string) {
    return this.http.post<any>(`${environment.baseUrl}/users/changePassword`, { currentPassword, newPassword });
  }

  /**
   * Change email
   * @param email email to be changed
   */
  changeEmail(email: string) {
    return this.http.post<any>(`${environment.baseUrl}/users/changeEmail`, { email });
  }

  /**
   * Get popular videos List
   */
  getPopularTangrams() {
    return this.http.get(`${environment.baseUrl}/pathway/popular/list`);
  }
}
