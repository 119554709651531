import { Component, Input, OnInit } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'tangram-curator-info',
  templateUrl: './curator-info.component.html',
  styleUrls: ['./curator-info.component.scss']
})
export class CuratorInfoComponent implements OnInit {
  @Input() curatorInfo: any;
  iconPath = appConfig.iconPath;
  imageUrl = environment.backendUrl;
  constructor() { }

  ngOnInit() {
  }

}
