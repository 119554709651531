import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../../../_core/services/validation.service';
import { AuthService } from '../../../../_services/auth.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { appConfig } from 'src/app/_core/config/app-config.const';
import { CommonService } from '../../../../_services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare let gtag: Function;
@Component({
  selector: 'tangram-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  // get image path
  imgPath = appConfig;
  registerForm: FormGroup; // To store form reference
  isRegisterFormSubmitted: boolean = false; // To store status about register form submission
  registerFormValueChangesSubscription: Subscription;
  passwordPatternValidationMessages: string[] = []; // To store password validation messages
  isPasswordFieldClicked: boolean = false; // To indicate that password field is clicked
  isConfirmPasswordFieldClicked: boolean = false; // To indicate that confirm password field is clicked
  serverErrors: any = {};
  hideControlsExceptScreenName: boolean = false; // To hide controls except screen name
  onlyScreenNameSubscription: Subscription;
  userId: string = ''; // To store user id
  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private validationService: ValidationService,
    public authService: AuthService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private commonService: CommonService
  ) { }

  /**
   * Called when comopnent being initialized
   */
  ngOnInit() {
    this.renderer.removeClass(document.body, 'home-page');
    this.buildRegisterForm();
    this.checkOnlyScreenNameRequired();
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  /** Build register form */
  private buildRegisterForm() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      screenName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.validationService.regex.email)]],
      password: ['', [Validators.required, Validators.pattern(this.validationService.regex.password)]],
      confirmPassword: ['', Validators.required],
    },
      { validators: this.validationService.comparePassword("password", "confirmPassword") }
    );
    this.passwordPatternValidationMessages = this.validationService.getPasswordPatternValidationMessages(this.registerForm.controls['password'].value);
    // Get validation error when register form values get changed
    this.registerFormValueChangesSubscription = this.registerForm.valueChanges.subscribe((register) => {
      this.passwordPatternValidationMessages = this.validationService.getPasswordPatternValidationMessages(register.password);
    });
  }

  /**
   * Check only screen name required or not based on social login
   */
  checkOnlyScreenNameRequired() {
    this.onlyScreenNameSubscription = this.commonService.onSocialLogin$.subscribe((userId: string) => {
      // If user id available means social login is done and come here for the entering screen name for this user
      if (userId) {
        // Reset event with null user id
        this.commonService.raiseEventToHideRegistrationControlsExceptScreenName(null);
        this.userId = userId;
        // Clear validation for the controls except screen name
        Object.keys(this.registerForm.controls).forEach((key: string) => {
          if (key != 'screenName') {
            this.registerForm.controls[key].clearValidators();
            this.registerForm.controls[key].updateValueAndValidity();
          }
        });
        this.hideControlsExceptScreenName = true;
      }
    });
  }

  /**
   * Called when register form gets submitted
   * @param isRegisterFormValid isRegisterFormValid to check whether register form is valid or not
   */
  onSubmit(isRegisterFormValid) {
    this.isRegisterFormSubmitted = true; // Indicate that form is submitted
    if (isRegisterFormValid) {
      // If user id available then update screen name otherwise register user
      if (this.userId) {
        // Bind screen name
        let data = { screenName: this.registerForm.value.screenName };
        // Update user detail with screen name
        this.authService.updateUserDetail(this.userId, data).subscribe((response) => {
          gtag('event', 'register_success', {
            'register_email': this.registerForm.value.email,
          });
          this.router.navigate(['/configuration']);
          // this.router.navigate(['/login']);
        }, (error) => {
          this.serverErrors = this.validationService.getServerErrorsFromResponse(error.error.message, this.registerForm);
        });
      }
      else {
        // Call execute method for getting recaptch v3 token
        this.reCaptchaV3Service.execute(environment.recaptchaSiteKey, 'submit', (token) => {
          // Verify recaptcha V3 token
          this.authService.verifyRecaptchaV3Token(token).subscribe((data: any) => {
            // Check if token is valid then register the admin and redirect to login page
            if (data.success) {
              let data = this.registerForm.value;
              this.authService.registerUser(data).subscribe((response: any) => {
                let message = `${data.firstName} ${data.lastName}, an email has been sent to ${data.email} to confirm your email address. Please confirm your email address in order to use all the functions of the <a href='http://tangram.io/' target='_blank'>Tangram.io</a> application.`;

                // Open modal to show success message
                gtag('event', 'register_success', {
                  'register_email': this.registerForm.value.email,
                });
                this.showMessageInSweetModal(message, response.userId);

                // If user id and name available in response then set it in local storage for further usage in configuration flow               
                if (response.userId && response.name) {
                  let registeredUser = { userId: response.userId, name: response.name }
                  localStorage.setItem("tangramRegisteredUser", JSON.stringify(registeredUser));
                }
              }, (error) => {
                this.serverErrors = this.validationService.getServerErrorsFromResponse(error.error.message, this.registerForm);
              });
            }
          });
        });
      }
    }
  }

  /**
   * Go to login page
   */
  goToLogin() {
    this.router.navigate(['login']);
  }

  /**
   * Show message in sweet modal
   * @param message message to be displayed
   * @param userId userId to be used for sending email
   */
  showMessageInSweetModal(message, userId) {
    Swal.fire({
      html: message,
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Resend verification email',
      focusConfirm: false,
      cancelButtonText: 'OK',
      reverseButtons: true
    }).then((result) => {
      // Called when click on 'Resend verification email' button
      if (result.isConfirmed) {
        this.resendVerificationEmail(userId);
      }
      else if (result.isDismissed) { // Called when click on outside modal or 'OK' button
        this.redirectToConfiguration();
      }
    });
  }

  /**
   * Resend verification email
   * @param userId
   */
  resendVerificationEmail(userId: string) {
    this.authService.resendVerificationEmail(userId).subscribe((res: any) => {
      this.showMessageInSweetModal(res.message, userId);
    });
  }

  /**
   * Redirect to configuration
   */
  redirectToConfiguration() {
    this.router.navigate(['/configuration']);
  }
 
  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    if (this.registerFormValueChangesSubscription) {
      // clean up register form value changes subscription
      this.registerFormValueChangesSubscription.unsubscribe();
    }

    if (this.onlyScreenNameSubscription) {
      // clean up only screen name subscription
      this.onlyScreenNameSubscription.unsubscribe();
    }
  }
}
