import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tangram-content-completion',
  templateUrl: './content-completion.component.html',
  styleUrls: ['./content-completion.component.scss']
})
export class ContentCompletionComponent implements OnInit {
  @Input() contentProcessed: any;
  @Input() isDashboard: boolean;
  constructor() { }

  ngOnInit() {
  }

}
