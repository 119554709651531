import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { CommonService } from './_services/common.service';

// declare gtag as a function to access the JS code in TS
declare let gtag: Function;
@Component({
  selector: 'tangram-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tangram-angular';
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    
  ) {
    /** check device is mobile or desktop */
    if (this.commonService.checkDeviceIsMObileOrNot()) {
      gtag('event', 'device_type', {
        'event_category': 'fetch_device',
        'event_label': 'mobile'
      });
    } else {
      gtag('event', 'device_type', {
        'event_category': 'fetch_device',
        'event_label': 'web'
      });
      // this
      //   .googleAnalyticsService
      //   .eventEmitter('device_type','fetch_device','web');
    }
  }
}
