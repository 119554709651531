import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';

@Component({
  selector: 'tangram-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit {
  iconPath = appConfig.iconPath;
  @Input() playlist: any;
  @Output() onChangePlaylist = new EventEmitter();
  previewVideoThumbnail: string = "assets/img/videos/img6.png"; // To store image url

  constructor() { }

  ngOnInit() {
  }
  onPlaylistClick(playlist) {
    this.onChangePlaylist.emit(playlist);
  }

}
