import { Component, OnInit } from '@angular/core';
import { appConfig } from 'src/app/_core/config/app-config.const';

@Component({
  selector: 'tangram-configuration-layout',
  templateUrl: './configuration-layout.component.html',
  styleUrls: ['./configuration-layout.component.scss']
})
export class ConfigurationLayoutComponent implements OnInit {
  imgPath = appConfig.imagePath;
  constructor() { }

  ngOnInit() {
  }

}
