import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { DownloadModalComponent } from './components/download-modal/download-modal.component';
import { CommentInputSelectionComponent } from './components/comment-input-selection/comment-input-selection.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlagConfirmationModalComponent } from './components/flag-confirmation-modal/flag-confirmation-modal.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShowProfileModalComponent } from './components/show-profile-modal/show-profile-modal.component';
import { DragSliderDirective } from './directives/drag-slider.directive';


@NgModule({
  declarations: [
    FeedbackComponent,
    DownloadModalComponent,
    CommentInputSelectionComponent,
    FlagConfirmationModalComponent,
    CommonModalComponent,
    ShowProfileModalComponent,
    DragSliderDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    NgbModule,
    SlickCarouselModule,
    NgSelectModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgxCaptchaModule,
    FeedbackComponent,
    CommentInputSelectionComponent,
    DownloadModalComponent,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CommonModalComponent,
    SlickCarouselModule,
    ShowProfileModalComponent,
    FlagConfirmationModalComponent,
    DragSliderDirective,
  ],
  entryComponents: [
    DownloadModalComponent,
    FeedbackComponent,
    FlagConfirmationModalComponent,
    CommonModalComponent,
    ShowProfileModalComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]

})
export class SharedModule {
}
