import { Component, Input, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CommonModalComponent } from 'src/app/shared/components/common-modal/common-modal.component';
import { CommonService } from 'src/app/_services/common.service';
import videojs from 'video.js';
import 'videojs-markers';
import 'videojs-youtube';
import { DashboardService } from '../../services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';


@Component({
  selector: 'tangram-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() videoId: string = ""; // To store video id
  @Input() allVideos: any;
  @Input() videoDetail: any = {}; // To store video detail
  @Input() trackVideoDetail: any = {}; // To store tracked video detail
  @Input() createPage: any = ""; // To store create page or not
  @Output() updateConsumedContent = new EventEmitter();

  @Input() isPublic: boolean = false;
  @Output() onLoadNextVideo = new EventEmitter();
  @Output() onChangeDashboard = new EventEmitter();
  @Output() onChangePlaylist = new EventEmitter();
  @ViewChild('tangramPlayer', { static: true }) tangramPlayer: ElementRef; // To reference of the player
  player: videojs.Player; // To store palyer information
  videoContentConsumedPercentage: Subscription;
  isVideoPlayed: boolean = false; // To check whether video played or not
  isHotSpotAPICalled: boolean = true;
  _offsetStart = this.videoDetail.startTimeInSecond;
  _offsetEnd = this.videoDetail.endTimeInSecond;
  isSeekTime: boolean = false;
  currentTime: any;
  seekTime: any;
  isVideoSeekedByUser: boolean = false;
  updatedTime: any = this.trackVideoDetail.totalSeenSeconds;
  videoStopped: boolean = false;
  playVideo: boolean = false;
  isVideoPaused: boolean = false;
  visibility: string = ""; // To check id public page or not
  currentUser: any;
  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private dashboardService: DashboardService,
    ) {
      this.currentUser = this.authService.currentUserValue;
    }

  /**
   * Called when input properties get changed
   * @param changes changes to be got
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.videoId) {
      this.cd.detectChanges();
      // Update current video % content consumed when play new video
      if (changes.videoId && changes.videoId.previousValue) {
        this.emitUpdateConsumedContent(changes.videoId.previousValue);
      }
      this._offsetStart = this.videoDetail.startTimeInSecond;
      this._offsetEnd = this.videoDetail.endTimeInSecond;
      // this.updatedTime = this.trackVideoDetail.totalSeenSeconds;
      this.loadVideoInPlayer();
    }
  }

  /**
   * Called when component being called
   */
  ngOnInit() {
    if (this.activatedRoute.parent.snapshot.routeConfig.path == 'video/:id' || this.isPublic) {
      this.visibility = 'public';
    }
    this.handleContentConsumedUpdationSubscription();
    // initialize player
    this.initializePlayer();
  }

  handleNext() {
    let videoList = this.allVideos;
    let nextVideo;
    videoList.forEach((val, index) => {
      if (val._id == this.videoDetail._id) {
        nextVideo = this.allVideos[index + 1];
        if (index + 1 >= this.allVideos.length) {
          nextVideo = this.allVideos[0];
        }
      }      
    })
    this.videoDetail = nextVideo;
    this.onChangePlaylist.emit(this.videoDetail);
  }

  handlePrev() { 
    let videoList = this.allVideos;
    let nextVideo: any;
    videoList.forEach((val, index) => {
      if (val._id == this.videoDetail._id) {
        nextVideo = this.allVideos[index - 1];
        if (index - 1 < 0) {
          let length = this.allVideos.length;
          nextVideo = this.allVideos[length - 1];
        }
      }
    })
    this.videoDetail = nextVideo;
    this.onChangePlaylist.emit(this.videoDetail);
  }

  /**
   * Initialize player
   */
  initializePlayer() {
    // instantiate Video.js
    this.player = videojs(this.tangramPlayer.nativeElement, {}, () => {
      this._offsetStart = this.videoDetail.startTimeInSecond;
      this._offsetEnd = this.videoDetail.endTimeInSecond;
      let previousTime = 0,
        currentTime = 0,
        completeTime = 0,
        seekToStart = this._offsetStart,
        position = 0;
      // Called when video gets played
      this.player.on("play", () => {
        if(!this.createPage && !this.isPublic) {
          this.loadMarkersInSeekbar();
        }
        if (this.trackVideoDetail.totalSeenSeconds && !this.isVideoPlayed) {
          this.isVideoPlayed = true;
          this.player.currentTime(this.trackVideoDetail.percentage === 100 ? this._offsetStart : this.trackVideoDetail.totalSeenSeconds ? this.trackVideoDetail.totalSeenSeconds : this._offsetStart);
          this.isVideoPaused = false;
        }
      });

      // set flag video is playing
      this.player.on("playing", () => {
        this.isVideoSeekedByUser = false;
        this.isVideoPaused = false;
        this.handleVideoStart();
        // this.playVideo = true;
      })


      // restirct player video with particular starttiem and endtime
      this.player.on("timeupdate", () => {
        previousTime = this._offsetStart,
          currentTime = Math.floor(this.player.currentTime());
        // save 'position' so long as time is moving forward with each update
        if (previousTime < currentTime) {
          position = previousTime;
          previousTime = currentTime;
        }

        if (!this.isVideoSeekedByUser && (this.player.currentTime() >= this._offsetEnd)) {
          this.player.pause();
          this.player.trigger('ended');
        }
      
      });


      // Called when video gets paused
      this.player.on("pause", () => {       
        this.isVideoPaused = true;              
        seekToStart = previousTime;
        this.emitUpdateConsumedContent(this.videoId);
      });

      this.player.on('seeking', () => {
        this.isVideoSeekedByUser = true;
        this.player.pause();       
      });

      this.player.on("seeked", () => {
        completeTime = Math.floor(this.player.currentTime());
        if ((completeTime < this._offsetStart) || (completeTime > this._offsetEnd)) {
          this.isVideoSeekedByUser = true;
          this.player.currentTime(this.isVideoPaused ? seekToStart : previousTime);
          if (this._offsetStart) {
            // Open popup
            const modalRef = this.modalService.open(CommonModalComponent, { centered: true });
            let startTimeHour = ((this.videoDetail.startTime.hour).toString().length == 2 ? (this.videoDetail.startTime.hour) : '0' + (this.videoDetail.startTime.hour));
            let startTimeMinute = ((this.videoDetail.startTime.minute).toString().length == 2 ? (this.videoDetail.startTime.minute) : '0' + (this.videoDetail.startTime.minute));
            let startTimeSecond = ((this.videoDetail.startTime.second).toString().length == 2 ? (this.videoDetail.startTime.second) : '0' + (this.videoDetail.startTime.second));
            const startTimeFormat =  startTimeHour + ':' + startTimeMinute + ':' + startTimeSecond;
            let endTimeHour = ((this.videoDetail.endTime.hour).toString().length == 2 ? (this.videoDetail.endTime.hour) : '0' + (this.videoDetail.endTime.hour));
            let endTimeMinute = ((this.videoDetail.endTime.minute).toString().length == 2 ? (this.videoDetail.endTime.minute) : '0' + (this.videoDetail.endTime.minute));
            let endTimeSecond = ((this.videoDetail.endTime.second).toString().length == 2 ? (this.videoDetail.endTime.second) : '0' + (this.videoDetail.endTime.second));
            const endTimeFormat = endTimeHour + ':' + endTimeMinute + ':' + endTimeSecond;
            // modalRef.componentInstance.data = `We have curated videos most relevant to users and only show select snippets. It's curated video and it's most relevant content would be available between from time ${startTimeFormat} and end time ${endTimeFormat}.`;
            modalRef.componentInstance.data = `The tangram curator has chosen the best moment of this video from ${startTimeFormat} to ${endTimeFormat}. Tap video to play. Press Tangram to comment and react.`;
            modalRef.componentInstance.isButton=true;
            modalRef.result.then(() => {                          
            }, () => {
            });
          }
        }
      });

      let that = this;
      // Set markers design
      this.player.markers({
        markerStyle: {
          'width': '13px',
          'height': '13px',
          'background-color': 'transparent'
        },
        markerTip: {
          display: false,
          text: function text(marker) {
            return marker.text;
          },
          time: function time(marker) {
            return marker.time;
          }
        },
        onMarkerClick(marker) {
          that.player.on("play", () => {
            that.player.play();
            that.isVideoPlayed = true;
          });
          let videoData = {
            videoId: that.videoId,
            timeSlot: marker.time,
            hotspot: true,
          }
          that.emitLoadVideoComments(videoData);
        },
        display: true,
        markers: []
      });


      // Called when video gets ended
      this.player.on("ended", () => {
        this.onLoadNextVideo.emit();
        // this.videoStopped = !this.videoStopped;
        // if (!this.videoStopped) {
        //   if (this.trackVideoDetail.percentage === 100 && this._offsetStart > 0) {
        //     this.player.currentTime(previousTime);
        //     // this.playVideo = false;           
        //   } else {
        //     if (this.playVideo) { this.onLoadNextVideo.emit(); }

        //   }
        // } else {
        //   if (this.playVideo) { this.onLoadNextVideo.emit(); }
        // }

        // Update consumed content
        this.emitUpdateConsumedContent(this.videoId);
      });
    });
  }

  /**
   * Handle content consumed updation subscription
   */
  handleContentConsumedUpdationSubscription() {
    // Receive event to update video content consumed percentage and show dashboard
    this.videoContentConsumedPercentage = this.commonService.updateVideoContentConsumedPercentage.subscribe(() => {
      if (this.videoId) {
        this.emitUpdateConsumedContent(this.videoId);
        this.onChangeDashboard.emit();
      }
    });
  }

  /**
   * Load video in player
   */
  loadVideoInPlayer() {
    setTimeout(() => {
      // Set poster
      this.player.poster(this.videoDetail.thumbnail.mediumURL);
      this.player.src({ type: 'video/youtube', src: this.videoDetail.videoURL });
      this.player.load();
      // this.player.currentTime(this._offsetStart);
      this.player.markers.reset([]);
      this.isVideoPlayed = false;
      this.isHotSpotAPICalled = true;
      this.commonService.setVideoPlayer(this.player);
    }, 500);
  }

  /**
   * Load markers in seekbar
   */
  loadMarkersInSeekbar() {
    if (this.isHotSpotAPICalled) {
      this.isHotSpotAPICalled = false;
      this.dashboardService.getVideoHotSpots(this.videoId, this.visibility).subscribe((res: any) => {
        let hotSpots = [];
        for (let data of res.data.comment) {
          let hotSpot = { time: data.startTime, text: data.commentCount ? data.commentCount : 0, class: "comment-marker" };
          hotSpots.push(hotSpot);
        }

        for (let data of res.data.hotspot) {
          let typeClass;
          if (data.type == 'QUESTION') { typeClass = "question-marker" }
          if (data.type == 'SUGGESTION') { typeClass = "suggest-marker" }
          if (data.type == 'RECOMMEND') { typeClass = "recommend-marker" }
          if (data.type == 'DISCUSS') { typeClass = "discuss-marker" }
          let hotSpot = { time: data.startTime, text: data.comment ? data.comment : "", class: typeClass };
          hotSpots.push(hotSpot);
        }
        setTimeout(() => {
          this.player.markers.reset(hotSpots);
        });
      });
    }
  }

  /**
   * Call parent component to load comments as per timeslot
   * @param videoData videoData to be updated
   */
  emitLoadVideoComments(videoData: any) {
    this.commonService.chatStreamDataFromVideo.emit(videoData);
  }

  /**
   * Call parent component to update consumed content
   * @param videoId videoId to be updated
   */
  emitUpdateConsumedContent(videoId: string) {
    let videoDetail = {
      "videoId": videoId,
      "totalSeconds": this.player.duration(),
      "totalSeenSeconds": this.player.currentTime()
    };
    // If video length is available then only update consumed content
    if (videoDetail.totalSeconds > 0) {
      this.updateConsumedContent.emit(videoDetail);
    }
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.videoContentConsumedPercentage) {
      this.videoContentConsumedPercentage.unsubscribe();
    }
  }

  /**
   * Handles start time of video based on offset time
   */
  handleVideoStart() {
    let completeTime = Math.floor(this.player.currentTime());
    if ((completeTime < this._offsetStart) || (completeTime > this._offsetEnd)) {
      this.player.pause();
      this.player.currentTime(this._offsetStart);
    }
  }

}
