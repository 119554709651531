import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { appConfig } from 'src/app/_core/config/app-config.const';

@Component({
  selector: 'tangram-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  iconPath = appConfig.iconPath;
  imgPath = appConfig.imagePath;
  data:any;
  isButton:any;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}
